import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Hidden from '@material-ui/core/Hidden';


import NatureButton from '../../displays/NatureButton';

const styles = ({ palette, spacing }: Theme) => ({
	root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: palette.background.paper,
    },
    header:{
    	height: spacing(16),
    	width: "100%",
    	backgroundColor: palette.primary.dark,
    },
    gridList: {
      width: "100%",
      transform: 'translateZ(0)', 
      marginTop: spacing( 0 ), 
      marginBottom: spacing( 0 ), 
    },
    gridItem:{
    	margin: 0,
    	padding: 0,
    	position: "relative",
    	overflow: "hidden",
    	height: spacing(60),
 		backgroundRepeat: "no-repeat",
  		backgroundSize: "cover",
  		backgroundPosition: "100%, 0",
    },
    gridImg:{
    	width: "100%"
    	  	
    },
	titleBarTop: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    titleBarBottom: {
      background: "none",
      paddingBottom: spacing(2),
    },
});

const carouselSrc = "https://naturetravel.life/content/images/animals/"

const tileData = [
    {
      	title: 'Mediterranean Monk Seal',
      	url: '/animals/mediterranean-monk-seal',
      	img:  carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg",
      	location: 'Izmir, Turkey',
      	cols: 8,
    },
    {
      	title: 'Loggerhead Sea Turtle',  
      	url: '/animals/caretta-caretta',
      	img:  carouselSrc +"caretta-caretta/carousel-caretta-500.jpg",
      	location: 'Dalyan, Turkey',
      	cols: 4,
    },
    {
      	title: 'Northern Bald Ibis',    	
      	img:  carouselSrc +"northern-bald-ibis/carousel-northern-bald-ibis-500.jpg",
      	location: 'Sanliurfa, Turkey',
      	cols: 3,
    },
];


const AnimalList = (props) => {
	const { classes } = props

	return <div className={classes.root}>
		<Hidden smDown>
			<div className={classes.header}></div>
		</Hidden>
		<Grid container spacing={2} className={classes.gridList} > 
			{tileData.map((tile) => {
        		let tileColsSM = tile.url? tile.cols : 4
        		let tileColsXS = tile.url? 12 : 6
        		let button  = ""
        		if(tile.url){
        			button = <GridListTileBar
			              titlePosition="bottom"
			              actionIcon={<div>
			              	<NatureButton contained light url={tile.url} label="Learn More" />
			                </div>
			              }
			              actionPosition="right"
			              className={classes.titleBarBottom}/>
        		}
        		return <Grid item sm={tileColsSM} xs={tileColsXS} key={tile.title}>
        					<div className={classes.gridItem} 
        						style={{backgroundImage: "url("+ tile.img +")"}}>
            		
			            		<GridListTileBar
					              title={tile.title + " - "+ tile.location}
					              titlePosition="top"
					              className={classes.titleBarTop}/>
					            {button}
					        </div>
		       	 	</Grid>
		    })}
		</Grid>
   	</div>
}

export default withStyles(styles)(AnimalList);