import React from 'react';

import Typography from '@material-ui/core/Typography';

import { withStyles,useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {  ParallaxBanner } from 'react-scroll-parallax';


const heightXs = 30;
const heightMd = 65;
const styles = ({ spacing,breakpoints }: Theme) => ({
  	root:{
    	fontSmoothing: "antialiased",
    	color: "#FFF",  	
  	},
    content:{
      display: "flex",  
      textAlign: "center",       
      flexDirection: "column",
      height: spacing( heightXs ),
      [breakpoints.up('md')]: {
        height: spacing(heightMd),
      },
      zIndex: 2,
      position: "relative",
      justifyContent: "center",  
      filter: "blur(0px)",    
    },
    title:{
      margin: 0,
      padding:0,		  
      letterSpacing: "3px",
      color: "#FFFFFF",
      textTransform: "uppercase",
	  },
	  subtitle:{
      fontStyle: "italic",
		  marginTop: "8px",
		  color: "#fff"
	 },
});

const HeaderTitleBox = (props) => {
  	const {classes} = props
  	const theme = useTheme();
 
  	const isSm = useMediaQuery(theme.breakpoints.up('sm'));
   const isMd = useMediaQuery(theme.breakpoints.up('md'));

  	let spacingBanner =  heightXs
  	if( isSm ){
   	spacingBanner = heightMd
  	}
  	if( isMd){
   	spacingBanner = heightMd    
  	}

   let img = props.image
 /*layers={[
              {
                  amount: 0.8,
                  children: <img className={classes.slideImg}
                    alt="Mediterranean Monk Seal"
                    srcSet={carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg 1024w,"
                      + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-800.jpg 800w,"
                      + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg 500w"} />,
              },
          ]}*/
  if( props.parallax ){
    	return <div className={classes.root} >
        	<ParallaxBanner
            layers={[
                {
                    image: img,
                    amount: 0.6,
                },
            ]}
            style={{
              height: theme.spacing(spacingBanner),
           }}>
           <div className={classes.content} >
              <Typography component="h1" variant="h1" className={classes.title} >{props.title}</Typography>
              <Typography component="h3" variant="h3" className={classes.subtitle} >{props.subtitle}</Typography>        

           </div>
        	</ParallaxBanner>
      </div>
  }else{
		return <div className={classes.root} style={{backgroundImage: "url("+props.image+")"}}>
        	
         <div className={classes.content} >{}
         	<Typography component="h1" variant="h1" className={classes.title} >{props.title}</Typography>
            <Typography component="h3" variant="h3" className={classes.subtitle} >{props.subtitle}</Typography>
        	</div>
      </div>
  }
}

export default withStyles(styles)(HeaderTitleBox)