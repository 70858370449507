import React from 'react';
import { Router } from "@reach/router"
import { ThemeProvider } from '@material-ui/core/styles';
import SimpleReactLightbox from 'simple-react-lightbox'

import { useTranslation } from 'react-i18next';

import ReactGA from 'react-ga';

import HomeContainer from './pages/HomeContainer';
import AnimalContainer from './pages/AnimalContainer';
import ActivityContainer from './pages/ActivityContainer';
import AnimalListContainer from './pages/AnimalListContainer';
import AboutContainer from './pages/AboutContainer';
import ContactContainer from './pages/ContactContainer';

import CssBaseline from '@material-ui/core/CssBaseline';

import { theme } from '../themes/main';

ReactGA.initialize('UA-25653552-7');
ReactGA.pageview(window.location.pathname + window.location.search);


let Home = () => <HomeContainer />
let Animal = () => <AnimalContainer />
let AnimalList = () => <AnimalListContainer />
let About = () => <AboutContainer />

//<Route path=":/lang([a-z]{2})" >
function App() {
    const { t,i18n } = useTranslation();

    const getUrl = ( key, lng ) => t("routes."+ key,{ lng: lng }) 
    const lng = i18n.language

    return <ThemeProvider theme={theme}>
  		<CssBaseline />
    	<SimpleReactLightbox>
			<Router>
  			<Home path="/:lng/" />
  			<Animal path={"/:lng/"+ getUrl('animals',lng) +"/:animalUrl"} />
        <ActivityContainer path={"/:lng/"+ getUrl('activity',lng) +"/:activityUrl"} />
        <AnimalList path={"/:lng/"+ getUrl('animals', lng) } />
        <About path={"/:lng/"+ getUrl('about',lng)} />
        <ContactContainer path={"/:lng/"+ getUrl('contact',lng)} />

          <Home path="/" />
          <Animal path={"/"+ getUrl('animals','en') +"/:animalUrl"} />
          <ActivityContainer path={"/"+ getUrl('activity','en') +"/:activityUrl"} />
          <AnimalList path={"/"+ getUrl('animals','en') } />
          <About path={"/"+ getUrl('about','en')} />
			</Router>
    	</SimpleReactLightbox>
  	</ThemeProvider>
}

export default App;
