import React from 'react';
import cn from 'classnames';


import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden';


const styles = ({ spacing,palette }: Theme) => ({
  	root: {
      fontSmoothing: "antialiased",
  	},
   colPhoto:{
      backgroundSize: 'auto 100% ',
      minHeight: spacing(100),
   },
   colText: {
      backgroundColor: "#f5f5f5",
      padding: spacing( 10 ),
      paddingRight: spacing(14),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
   },
   aboutBox:{
      display: "flex",   
      marginBottom: spacing(4) 
   },
   avatar:{
      marginRight: spacing(8),
      height: spacing(8),
      width: spacing(8),
   },
   title: {
      color: palette.accentDark.color,
      textTransform: "uppercase",
      letterSpacing: "2px",
      marginTop: spacing(1),
      marginBottom: spacing(2),
   },
   description: {
      color: palette.text.primary,
      fontSize: "0.8rem",
   },
   buttonContainer:{
      textAlign: "right",
   },
});

const PhotoColList = (props) => {
  const {classes} = props

 
  return <Grid container className={cn(classes.root)} >
      <Hidden smDown>
     		<Grid item md={4} className={classes.colPhoto}
            style={{backgroundImage:"url("+ props.image +")"}}>
      	</Grid>
      </Hidden>
      <Grid item md={8} className={classes.colText}>
         {props.content}
      </Grid>
   </Grid>
 
}

export default withStyles(styles)(PhotoColList)