export const izmirBirdParadiseEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`Un tranquilo paseo en bicicleta rodeado de miles de flamencos, 
		pelícanos y otras aves mar es una experiencia única que se puede hacer justo al 
		lado de una de las ciudades más grandes de Turquía, en Izmir, donde cada año más de 50.000 
		flamencos y otras aves marinas paran a veranear.`,
		`Recientemente se ha creado la isla artificial
		para flamencos más grande del mundo, de 6.440 metros cuadrados, 
		donde se espera que 20.000 flamencos aniden cada año.`,
		`Para que puedas disfrutar de este magnífico espectáculo, 
		hay más de 20 km de carril bici bordeando las marismas. 
		Que mejor forma de relajarse y desconectar del bullicio de la ciudad.`]
	},
	{
		type: "image",
		src: "bike-road-1.jpeg",
		label: "Carril Bici en Izmir"
	},
	{
		type: "title",
		content: "¿Por dónde va el carril bici?"
	},
	{
		type: "p",
		content: [`El carril bici empieza desde el distrito de Karşıyaka, en el pueblo de Alaybey, 
		justo al norte de Izmir y va recorriendo junto a el paseo marítimo toda la costa. 
		Es un recorrido muy bonito, con vistas magníficas de toda la bahía.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "paseo-maritimo-1.jpeg", title: "Pisos en el paseo marítimo de Izmir",cols: 2,rows: 2},
			{src: "paseo-maritimo-2.jpeg", title: "Paseo Marítimo de Izmir",cols: 2,rows: 2},
			{src: "paseo-maritimo-3.jpeg", title: "Monumento en el paseo marítimo de Izmir"},
			{src: "paseo-maritimo-4.jpeg", title: "Costa de Izmir"},
			{src: "paseo-maritimo-5.jpeg", title: "Carril bici junto la costa de Izmir"},
		],
	},
	{
		type: "p",
		content: [`Al acabarse la zona urbanizada empieza la primera zona de marismas donde 
		centenares de flamencos van rastreando sus aguas en busca de comida.`]
	},	
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "flamencos-1.jpeg", title: "Flamencos",cols: 2,rows: 2},
			{src: "flamencos-2.jpeg", title: "Flamencos en la bahía de Izmir",cols: 2,rows: 2},
			{src: "flamencos-3.jpeg", title: "Flamencos en el agua"},
			{src: "flamencos-4.jpeg", title: "Flamencos buscando comida"},
			{src: "flamencos-5.jpeg", title: "Flamencos en la laguna"},
			{src: "flamencos-6.jpeg", title: "Flamencos y gaviotas"},
		],
	},	
	{
		type: "p",
		content: [`
		El carril bici deja las marismas y continúa al lado de la carretera, 
		durante unos kilómetros un poco feos, pasa al lado de un gran parque perfecto para hacer picnics y del zoo de Izmir para llegar propiamente a la reserva de aves.`]
	},
	{
		type: "image",
		src: "parque.jpeg",
		label: "Parque junto al carril bici"
	},
	{
		type: "p",
		content: [`
		Dentro de la reserva son 6km más hasta llegar al centro del parque. El recorrido se hace por una carretera totalmente recta, donde no hay nada de tráfico, justo al lado de las marismas.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [			
			{src: "to-kuz-2.jpeg", title: "Flamencos en el santuario de Aves de Izmir"},
			{src: "to-kuz-3.jpeg", title: "Flamenco"},
			{src: "to-kuz-5.jpeg", title: "Flamencos en las salinas"},			
			{src: "to-kuz-7.jpeg", title: "Dos flamencos"},			
			{src: "to-kuz-4.jpeg", title: "Salinas de Izmir",cols: 2,rows: 2},
			{src: "to-kuz-6.jpeg", title: "Carretera junto a las salinas",cols: 2,rows: 2},
		],
	},	
	{
		type: "p",
		content: [`
		En el centro del parque se encuentra el parking para coches, un restaurante, y unas máquinas para alquilar las bicicletas. Algunos turistas han tenido problemas para alquilar las bicicletas debido a la tarjeta de crédito por lo que es mejor alquilar la bicicleta en la ciudad.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "kuz-2.jpeg", title: "Carretera en el Santuario de aves",cols: 2,rows: 2},
			{src: "kuz-4.jpeg", title: "Salinas del Santuario de aves",cols: 2,rows: 2},
			{src: "kuz-1.jpeg", title: "Ave en las salinas"},			
			{src: "kuz-3.jpeg", title: "Cormorán"},
		],
	},	
	{
		type: "title",
		content: "¿Es una ruta fácil?"
	},
	{
		type: "phtml",
		content: [`La ruta es muy fácil, y se puede adaptar a cada uno. 
		El recorrido es enteramente llano, pero depende del día puede soplar
		mucho viento haciendo difícil el pedalear.`,
		`Estas son las distancias dependiendo de donde se empiece:`,
		`<table>
			<thead>
				<tr>
					<th style="min-width: 200px;"></th>
					<th style="min-width: 150px;">Ida</th>
					<th style="min-width: 150px;">Vuelta</ht>
					<th style="min-width: 150px;">Total</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th>Inicio Carril Bici</th>  
					<td>27.7 km</td>
					<td>27.7 km</td> 
					<th>55.4 km</th>
				</tr>
				<tr>
					<th>Karsiyaka</th>  
					<td>26.6 km</td>
					<td>26.6 km</td> 
					<th>53.2 km</th>
				</tr>
				<tr>
					<th>Bostanli</th>  
					<td>24.3 km</td>
					<td>24.3 km</td> 
					<th>48.7 km</th>
				</tr>
			</body>
		</table>`,
		`Si al llegar al centro del parque aún tenéis energías se pueden investigar las salinas,
		que da para muchos kilómetros más. Eso si, ¡recordad que hay que volver a la ciudad!`,
		`Desde el centro del parque se puede hacer también un poco de recorrido caminando, 
		aunque las distancias son muy largas y puede hacerse pesado. `,
		`Para la gente que no quiera hacer tantos kilómetros es muy probable 
		que en las primeras marismas ya encontréis flamencos. En mi caso,
		yo encontré la mayoría de aves justo a la salida de la ciudad,
		por lo que no es necesario hacer el recorrido hasta el centro del parque, 
		con la mitad es suficiente.`]
	},
	{
		type: "image",
		src: "flamencos-7.jpeg",
		label: "Flamenco con ave marítima"
	},
	{
		type: "title",
		content: "¿Cómo llegar?"
	},
	{
		type: "phtml",
		content: [`Para llegar al Centro de Aves directamente, solo se puede hacer en coche, 
		pero si se quiere hacer la ruta en bicicleta saliendo desde Izmir 
		las mejores opciones son acercarse en tren o en bus.`,
		`El carril bici empieza cerca de la estación de metro Naldöken, 
		pero si no se quiere hacer el recorrido entero, 
		puede empezar la ruta desde las estaciones de Alaybey, Karsiyaka o Nergiz.`,
		`Una opción muy buena es llegar en bote desde Izmir Konak hasta Karsiyaka o Bostanli. 
		Teniendo en cuenta que sumando la ida y la vuelta es un recorrido bastante l
		argo Bostenli es un punto de inicio para los que no quieran hacer muchos kilómetros.`,
		`Puedes encontrar más información y horarios desde la web de los ferries:`,
		`<a href="https://www.izdeniz.com.tr/">www.izdeniz.com.tr/</a>`]
	},
	{
		type: "image",
		src: "monumento.jpeg",
		label: "Estatua de flamencos"
	},
	{
		type: "title",
		content: "¿Cuál es la mejor época?"
	},
	{
		type: "p",
		content: [`Los flamencos, siguen el ritmo de la mayoría de turistas, y vienen a Turquía en primavera y verano.  A la que empieza el frío marchan hacía África o lugares más cálidos.`]
	},
	{
		type: "image",
		src: "to-kuz-1.jpeg",
		label: "Pareja de flamencos buscando comida"
	}
]