import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';

//import Button from '@material-ui/core/Button';


const styles = ({ spacing,palette }: Theme) => ({
  	root:{
  		textAlign: "left",
  		minHeight: spacing(20),
  		padding: spacing( 8 ),  		
    	fontSmoothing: "antialiased",
  	},
    title:{
      marginBottom: spacing(1),
      color: palette.accent.color,
    },
	  subtitle:{
		  color: "#fff"
    },
    text:{
      marginTop: spacing(4),
      "& b": {
        color: palette.accentDark.color,
      }
    }
});

//      <Button>Learn More</Button>


const BoxBigSubtitle = (props) => {
  const {classes} = props

  let content = ""
  if( Array.isArray(props.text) ){
    content = props.text.map((t,index) => <Typography 
                          className={classes.text} 
                          key={"boxBigSubtitle"+ index}>{t}</Typography> )
  }else{
    content = props.text
  }
 
  return <div className={classes.root} >
      <Typography component="h2" variant="h2" className={classes.title}>{props.title}</Typography>
      <Typography component="h4" variant="h4" className={classes.subtitle}>{props.subtitle}</Typography>
      <Typography className={classes.text} 
          >{content}</Typography>
  	</div>
 
}

export default withStyles(styles)(BoxBigSubtitle)