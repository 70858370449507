export const ekodosdCenterEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`Si estás en Kusadasi no pierdas la oportunidad de visitar 
		el centro de cultura y vida salvaje de EKODOSD. Está justo en el centro del pueblo,
		 en una bonita casa tradicional turca, convertida ahora en museo.`]
	},
	{
		type: "image",
		src: "ekodosd-garden.jpeg",
		label: "Jardín del Centro de Naturaleza EKODOSD"
	},
	{
		type: "p",
		content: [`Recorre el jardín y las diferentes habitaciones de la casa,
		donde encontraras información sobre los animales y plantas que habitan Kusadasi, 
		así como algunas de las tradiciones que aún se mantienen en la región`]
	},
 	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "ekodosd-hall.jpeg", title: "Pasillo del centro EKODOSD",cols: 2,rows: 2},
			{src: "ekodosd-room-animals.jpeg", title: "Habitación con aves disecadas",cols: 2,rows: 2},
			{src: "ekodosd-hector.jpeg", title: "Panel informativo de los animales alrededor de Kusadasi"},
			{src: "ekodosd-room-traditional.jpeg", title: "Habitación con mobiliario tradicional"},			
			{src: "ekodosd-owl.jpeg", title: "Mochuelo siendo importunado por mi"},
		],
	},
	{
		type: "p",
		content: [`Ekodosd también se dedican a cuidar animales salvajes 
		que han sufrido algún problema o lesión para después devolverlos a su hábitat. 
		Con un poco de suerte quizás puedes llegar a conocer a alguno de estos huéspedes ocasionales!`]
	},
	{
		type: "image",
		src: "kukumav.jpeg",
		label: "Mochuelo"
	},
	{
		type: "phtml",
		content: [`Sobretodo no olvides de preguntar cuales son las siguientes actividades que hay planeadas. 
		Cada dos semanas hacen excursiones por los alrededores para observar aves, hacer fotografías, investigar antiguas ruinas o 
		simplemente para caminar entre bonitos paisajes.`,
		`Puedes encontrar más información sobre sus salidas en la página de 
		<a href="/es/actividad/kusadasi-paseos-naturaleza">Paseos por la naturaleza en Kusadasi</a>`]
	},
	{
		type: "title",
		content: "Horarios"
	},
	{
		type: "p",
		content: [`El centro normalmente abre de 9 de la mañana a 7 de la tarde 
		pero si los miembros tienen algún trabajo que hacer por los alrededores 
		es posible que esté cerrado.`,
		`Si quieres asegurarte de que está abierto puedes contactar con ellos con antelación.`]
	},
	{
		type: "image",
		src: "ekodosd-outside.jpeg",
		label: "En la entrada de EKODOSD con Bahattin"
	},
	{
		type: "title",
		content: "Contactos"
	},
	{
		type: "phtml",
		content: [`Para más información no dudes en contactar con ellos, 
		son muy amables y con gran interés en ayudar.`,		
		`<strong>Teléfono:</strong> 90 256 614 78 11<br/>
		<strong>Facebook:</strong> <a href="https://www.facebook.com/ekodosd">https://www.facebook.com/ekodosd</a><br/>
		<strong>Instagram:</strong> <a href="https://www.instagram.com/ekodosd">https://www.instagram.com/ekodosd</a>
		`]
	},
	
]