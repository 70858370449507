import React, {Component} from 'react';


import Page1Col from '../base/Page1Col';
import About from './about/About';


class AboutContainer extends Component {
    
	render(){

		let main = <About />
		return <Page1Col  main={main}  />
	}
}

export default AboutContainer