import React from 'react';

import cn from 'classnames';

import Carousel from './Carousel';

import ContactForm from '../../base/ContactForm';

import Info3Col from '../../base/Info3Col';
import BoxBigSubtitle from '../../displays/BoxBigSubtitle';
import Grid from '@material-ui/core/Grid';

import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';

import Typography from '@material-ui/core/Typography';
import NatureButton from '../../displays/NatureButton';
import Activity from '../../displays/Activity';

import {info3Col} from '../../../content/home';

import {activities} from '../../../content/activities';

import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles'

import {DOMAIN_CONTENT} from '../../../constants/constants';


const styles = ({ spacing,breakpoints,palette }: Theme) => ({
   	subtitle:{
   		//color: palette.accentDark.color,
   		color: palette.secondary.dark,
   		marginBottom: spacing(4)
   	},
	carouselBox:{		
		zIndex: 1,
		//paddingTop: spacing(6)
	},
	activitiesBox:{
		paddingTop: spacing(8),
		paddingBottom: spacing( 8 ),
		justifyContent: "center",
		/*display: "flex",
		justifyContent: "space-between",*/
	},
	aboutRow:{
		backgroundColor: palette.primary.main,
		color: palette.text.light,
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
	},
	aboutRow2:{
		backgroundColor: palette.primary.dark,
		color: palette.text.light,
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
	},	
	contactRow:{
		color: palette.text.light,
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
	},	
	aboutTitle :{
		color: palette.text.light,
    	textTransform: "uppercase",
    	letterSpacing: "2px",
    	marginTop: spacing(1),
    	marginBottom: spacing(2),
	},
	aboutDescription: {
    	color: palette.text.light,
    	textAlign: "left",
   	},
	colText:{
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		margin: spacing(8),
		[breakpoints.up('sm')]: {
			margin: 0,
        	paddingLeft: spacing(12),
      	},
	},
	colAvatar:{
		display: "flex",
		justifyContent: "center",
	},
	avatar:{
		width: spacing(32),
		height: spacing(32),		
	},
	avatarImg: {
		width: "100%",
	},
	avatarHuge:{
		width: spacing(54),
		height: spacing(54),
		[breakpoints.up('sm')]: {
			width: spacing(64),
			height: spacing(64),		
		}
	},
	title: {
    	color: palette.accentDark.color,
    	textTransform: "uppercase",
    	letterSpacing: "2px",
    	marginTop: spacing(1),
    	marginBottom: spacing(2),
   	},
   	description: {
    	color: palette.text.primary,
    	textAlign: "left",
   	},
});

/* <ParallaxBox 

			background="https://hectormarti.com/assets/content/photo/1/531/dsc04987.jpg"  >

    		<Info2Col content1={bgCol1} content2={bgCol2} />
    	</ParallaxBox>
*/

const Home = (props) => {
	const { classes } = props

    const { t } = useTranslation();


	const bgCol1 = <BoxBigSubtitle
		title={t("home.blockSustainable.title")}
		subtitle={t("home.blockSustainable.subtitle")}
		text={t("home.blockSustainable.description")}
	/>

	const bgCol2 = <BoxBigSubtitle
		title={t("home.blockAnimalProtection.title")}
		subtitle={t("home.blockAnimalProtection.subtitle")}
		text={t("home.blockAnimalProtection.description")}
	/>    

	return <div>		
		<div className={classes.carouselBox} >
			<Carousel />
		</div>

		<Hidden smDown>	  
			<Info3Col content={info3Col} />
		</Hidden>


    	<Grid container justify="center">
	  		<Grid item xs={10} sm={10} className={cn(classes.main,classes.activitiesBox)} >
	  			<Typography component="h3" variant="h2" className={classes.subtitle} >{t("activities.activities")}</Typography>
	  			<Grid container spacing={4}> 
					{activities.map( a => <Grid item xs={12} sm={4} key={"activity-grid-"+ a.key}>
						<Activity 
							short
							activity={a}
							location={a.location +", "+ a.country}/>
					</Grid>)}
				</Grid>
			</Grid>
		</Grid>

    	<Grid container justify="center" className={cn(classes.aboutRow)}>
	  		<Grid item sm={10} className={cn(classes.main)} >
	  			<Grid container>
	  				<Hidden smDown>	  			
						<Grid item xs={12} sm={6} className={cn(classes.colAvatar)}>
	     					<Avatar className={classes.avatarHuge}>
	     						<img
	     							alt="Apartamentos"
	     							className={classes.avatarImg}
	     							srcSet={DOMAIN_CONTENT + "images/home/bodrum_sq.jpg?width=500 1024w,"
	                      			+ DOMAIN_CONTENT + "images/home/bodrum_sq.jpg?width=300 800w,"
	                      			+ DOMAIN_CONTENT + "images/home/bodrum_sq.jpg?width=200 500w"} /></Avatar>
	      				</Grid>      	
	      			</Hidden>
      				<Grid item xs={12} sm={6} className={cn(classes.colAvatar)}>
     					{bgCol1}
      				</Grid>      				    				
    			</Grid>
    		</Grid>
    	</Grid>

    	<Grid container justify="center" className={cn(classes.aboutRow2)}>
	  		<Grid item sm={10} className={cn(classes.main)} >
	  			<Grid container>
	  				<Grid item xs={12} sm={6} className={cn(classes.colAvatar)}>
     					{bgCol2}
      				</Grid> 
      				<Hidden smDown>
						<Grid item xs={12} sm={6} className={cn(classes.colAvatar)}>
	     					<Avatar className={classes.avatarHuge}>
								<img
									alt="Cigueñas"
	     							className={classes.avatarImg}
	     							srcSet={DOMAIN_CONTENT + "images/home/stork_sq.jpg?width=300 1024w,"
	                      			+ DOMAIN_CONTENT + "images/home/stork_sq.jpg?width=200 800w,"
	                      			+ DOMAIN_CONTENT + "images/home/stork_sq.jpg?width=200 500w"} />
	                      	</Avatar>
	      				</Grid>
      				</Hidden>
    			</Grid>
    		</Grid>
    	</Grid>
    	
    	<Grid container justify="center" className={cn(classes.aboutRow)}>
	  		<Grid item sm={10} className={cn(classes.main)} >
	  			<Grid container>
      				<Hidden smDown>
     					<Grid item md={4} className={classes.colAvatar}>
     						<Avatar className={classes.avatar} alt="Héctor Martí" >
     							<img
     								alt="Héctor en los Himalayas"
     								className={classes.avatarImg}
     								srcSet={DOMAIN_CONTENT + "images/general/hector-himalaya.jpg?width=500 1024w,"
                      					+ DOMAIN_CONTENT + "images/general/hector-himalaya.jpg?width=300 800w,"
                      					+ DOMAIN_CONTENT + "images/general/hector-himalaya.jpg?width=200 500w"} />
							</Avatar>
      					</Grid>
      				</Hidden>
      				<Grid item md={8} className={classes.colText}>
      					<Typography component="h5" variant="h5" className={classes.aboutTitle}>{t("home.about.title")}</Typography>
         				<Typography className={classes.aboutDescription}>{t("home.about.description")}</Typography>
						<NatureButton url="/about-us" label={t("general.readMore")} />
      				</Grid>   					
   				</Grid>
   			</Grid>
   		</Grid>  

   		<Grid container justify="center" className={cn(classes.contactRow)}>	
   			<Grid item xs={10} className={cn(classes.main)} >
   				<Typography component="h3" variant="h2" className={classes.subtitle} >{t("contact.contact")}</Typography>
   				<ContactForm />
   			</Grid>
   		</Grid>
   	</div>
}

export default withStyles(styles)(Home);