import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles'

import {Person,EmojiNature,Explore} from '@material-ui/icons/';

import IconList from '../../displays/IconList';

import NatureButton from '../../displays/NatureButton';


const styles = ({ spacing,palette }: Theme) => ({
  	root: {
      fontSmoothing: "antialiased",
      backgroundColor: palette.background.paper,
  	},    
   colText: {
      padding: spacing( 8 ),
   },  
   section: {
      marginBottom: spacing( 6 ),
   },
   title: {
     color: palette.accentDark.color,
     marginBottom: spacing(2),      
     textAlign: "center"
   },  
   description: {
      color: palette.text.primary,
   },
});

const listDo = [
  {
    avatar: <Person />,
    background: "rgb(220,205,151)",
    title: "Reserach & Study",
    description: [`Very little is known about the Mediterranean Monk Seal. 
    A very important job is to know their habits - what they eat, where they breed, 
    what areas they travel... With this knowledge, it becomes possible to fight for 
    the creation of laws that will protect them efficiently.`]
  },
  {
    avatar: <EmojiNature />,
    background: "rgb(132,199,0)",
    title: "Protection of the environment",
    description: [`SAD-AFAG has been fighting to promote laws for the conservation of the environment. 
    Businesses are putting a lot of pressure on the environment, building houses, 
    hotels and apartments. That is hugely affecting the seals, who like to live in 
    quiet environments. Construction of illegal roads makes a big difference
    for the seals since they are being forced to find another place to rest. 
    The protection of the habitat of the seals also benefits all the ecosystem and other 
    species living there.`]
  },
  {
    avatar: <Explore />,
    background: "rgb(0,175,178)",
    title: "Promotion and awareness of the situation",
    description: [`Since the 90s, the organization has been explaining to the locals what is happening with the seals. 
    It will make the locals aware of the various possible ways they are harming the seals and how to avoid those activities 
    in future so as to prevent their extinction and promote their breeding and growth. `]
  },
] 

const Organization = (props) => {
  const {classes} = props

  return <Grid container className={cn(classes.root)} >  		
      <Grid item md={6} className={classes.colText}>
         <div className={classes.section}>
            <Typography component="h4" variant="h4" className={classes.title}>About SAD-AFAG</Typography>
            <Typography variant="body1" className={classes.description}>
              SAD-AFAG was founded more than 30 years ago and they have a very important place in 
              the conservation of the Mediterranean Monk Seal. They are the only dedicated NGO in 
              Turkey for the conservation and research of the species and its habitats.</Typography>         
         </div>
        
         <div className={classes.section}>
            <Typography component="h4" variant="h4" className={classes.title}>How can you help?</Typography>
            <Typography gutterBottom variant="h5" component="h5" className={classes.textTitle}>Be a Member of SAD-AFAG</Typography>
            <Typography>
               You can help the Mediterranean Monk Seals, being an 'AFAG Member'. 
               You will receive periodically to your email an e-journal to know what
                the organization is doing and what is the situation of the seals in Turkey.
            </Typography>
            <NatureButton contained dark external 
               label="Become a Member" url="https://sadafag.org/en/membership/" />

            <Typography gutterBottom variant="h5" component="h5" className={classes.textTitle}>Adopt a seal - Donation</Typography>
            <Typography>
               You can make a donation to the organization, with the symbolic adoption of a seal. 
               The cost is about 270€ USD or 270€ and also you will receive the e-journal to your email, 
               with a certificate and the photo of the seal.
            </Typography>
            <NatureButton contained dark external 
               label="Adopt a Seal" url="https://sadafag.org/en/mediterranean-monk-seal/adopt-a-monk-seal/" />
         </div>
      </Grid>
      <Grid item md={6} className={classes.colText}>
         <div className={classes.section}>
            <Typography component="h4" variant="h4" className={classes.title}>What are they doing?</Typography>

            <IconList list={listDo}/>
         </div>
      </Grid>
   </Grid>
 
}

export default withStyles(styles)(Organization)