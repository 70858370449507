import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import TitleBox from '../displays/TitleBox';
import Activity from '../displays/Activity';

import {activities} from '../../content/activities';


const styles = ({ spacing }: Theme) => ({
  	root: {
  		minHeight: spacing(20),
  		padding: spacing( 6 ),
    	fontSmoothing: "antialiased",
    	color: "#000",
      backgroundColor: "#f5f5f5",
  	},
  	list: {  	
  		margin: spacing(3),
      display: "flex",
      justifyContent: "space-around",
  	}


});

/*<Activity 
          title="Surveing seals in a wooden-boat"
          location="September 2020"
          date="Turkey Fethyie"
          image=""
            />
            */
const ActivityList = (props) => {
  const {classes} = props
 
  return <Grid container justify="center" className={cn(classes.root)}>
		<Grid item sm={10} className={cn(classes.main)} >
			<TitleBox title="Activities" /> 
			<div className={cn(classes.list)}>
        {activities.filter( (a) => a.animal === "monk-seal").map( a => <Activity 
            short
            image={a.image}
            title={a.title}
            dates={a.dates} 
            type={a.type}
            time={a.time} 
            location={a.location +", "+ a.country}
            description={a.short}
            contactLink={a.contactLink} /> ) }
        
        
 			</div>

		</Grid>
	</Grid>
}

export default withStyles(styles)(ActivityList)