export const kusadasiWalkingTourEn = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`La foca monje mediterranea es uno de los mamiferos más grandes de Europa, 
		con más una largada media de 2.4 metros y más de 230kg, 
		parece mentira lo desapercibidos que pasan. Solo quedan 700 ejemplares, 
		y en Turquia existe la poblacion de focas mas grande del Mediterraneo, 
		aunque por desgracia no sobrepasa los 100 individuos, repartidos por la inmensa costa Turca.`]
	},
	{
		type: "image",
		src: "foca-panorama.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Antiguamente las focas vivian pácificamente en las playas del Mediterraneo, 
		tostandose perezosamente en las soleadas arenas de las playas, 
		pero ya desde tiempos de los romanos empezaron a ser cazarlas por su piel y su carne.`,
		`Con el tiempo la situación de las focas no ha ido mejorando sino lo contrario. 
		Las focas eran el enemigo principal de los pescadores, ya que atraídas por el gran 
		volumen de peces atrapados entre las redes, las rompían y se ponian las botas con 
		un buen banquete de pescado. Los pescadores no estavan demasiado contentos con la 
		red rota y los peces perdidos por lo que cada vez que veían una foca las mataban.`]
	},
	{
		type: "image",
		src: "foca-fishermen.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Aún y todo algunas focas consiguieron sobrevivir pero hoy en dia tienen un reto aún 
		más dificil por superar. La perdida del habitat! Las focas son territoriales y 
		necesitan grandes espacios para vivir. Como son un poco insociables 
		(por eso se las llama foca monje), no les gusta demasiado tener miles de personas
		 banyandose alrededor, carreteras, o bloques de apartamentos justo al lado, 
		 y justamente lo que mejor sabemos hacer los hombres es ocupar territorio 
		 con cemento y asfalto. En muy pocos años todo el habitat de las focas ha 
		 sido reducido drasticamente, y como pasa en las grandes ciudades, la imposibilidad por encontrar
		 alojamiento estan dejando de criar. `]
	},
	{
		type: "image",
		src: "bodrum-apartments.jpeg",
		label: "Carril Bici"
	},
	{
		type: "title",
		content: "Sobre Foça"
	},
	{
		type: "p",
		content: [`Foça es una bonito y tranquilo pueblo marinero, cerca de Izmir. Situado en una pequeña cala,
		las estrechas (y poco abundantes) calles de la ciudad van bordeando el puerto y el paseo marítimo. `
		]
	},
	{
		type: "image",
		src: "foca-paseo.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Ya habitada desde hace milenios por los griegos 
		(Phocaea), han pasado por la ciudad bizantinos, genoveses y turcos otomanes, todos ellos dejando su marca
		en la ciudad. Castillos, antiguas ruinas romanas, y hasta se puede encontrar en las afueras una tumba persa.`]
	},
	{
		type: "image",
		src: "foca-persia.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Grandes colinas llenas de arbustos y bosques de pinos que acaban abrutamente en las aguas del mar,
		  hacen de la zona un pequeño paraiso.Las aguas transparentes y sembradas de islitas aún hoy en día son refugio de 
		algunas de las pocas focas que quedan.`]
	},
	{
		type: "image",
		src: "foca-water.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Foça siempre habia sido un pueblo famosos por sus focas. 
		Gracias a la organización  SAD-AFAG, se ha conseguido que la zona sea protegida, 
		y las focas del lugar pueden vivir con una cierta tranquilidad.`]
	},
	{
		type: "image",
		src: "foca-flag.jpeg",
		label: "Carril Bici"
	},
	{
		type: "title",
		content: "La península"
	},
	{
		type: "p",
		content: [`Desde Foça a Yenifoça hay una carretera, 
		sin apenas tráfico, que va subiendo y bajando las diferentes colinas resiguiendo 
		la costa.`]
	},
	{
		type: "image",
		src: "foca-coast.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Cada pocos kilómetros se puede ir encontrando magníficas y tranquilas playas. 
		Es imprescindible recorrer la carretera! Eso si  si decides hacerlo en bicicleta,
		 recuerda que hay bastantes subidas y el viento es increíblemente fuerte. 
		 Hacerlo de norte hacia el sur (Yenifoça a foça) ya que al reves es agotador.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "foca-beach-2.jpeg",title: "Paseo Marítimo Izmir",cols: 2,rows: 2},
			{src: "foca-island-2.jpeg",title: "Paseo Marítimo Izmir",cols: 2,rows: 2},
			{src: "foca-island.jpeg"},
			{src: "foca-beach.jpeg"},			
			{src: "foca-water-trees.jpeg"},
			{src: "foca-road.jpeg"},
		],
	},
	{
		type: "title",
		content: "Centro de Información de la Foca Monje"
	},
	{
		type: "p",
		content: [`En el centro del pueblo en el edificio de la Biblioteca hay el 
		Centro de Información de la Foca Monje, donde puedes encontrar información 
		de la foca, y los alrededores. Si quieres puedes hacer una visita al centro 
		para aprender más sobre este esquivo animal y así tener más posibilidades de encontrar una!`]
	},
	{
		type: "image",
		src: "foca-seal-center.jpeg",
		label: "Carril Bici"
	},
	{
		type: "title",
		content: "Horarios"
	},
	{
		type: "p",
		content: [`Puedes acceder al Centro de Información, de Lunes a Viernes de 10 a 12:30. 
		La entrada es gratuita.`]
	}
]