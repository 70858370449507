import Deer from '../images/icons/deer.png';
import Mountain from '../images/icons/mountain.png';
import Tent from '../images/icons/tent.png';

export const info3Col = {
	col1:{
		title: "Travel to the Nature",
		icon: Tent,
		description: [
  			`We promote a different kind of tourism 
  			- to help nature and to prevent the destruction to animals by
  			promoting accommodations that are helping to protect the environment.`
		]
	},
	col2: {
		title: "Protect the animals",
		icon: Deer,
		description: [
   		`A lot of species are disappearing just next to our homes. 
   		We will try to give voice to them, as well as to the organizations 
   		that are protecting them.`
		]
	},
	col3: {
		title: "Discover the wildlife",
		icon: Mountain,
		description: [
			`We are surrounded by a lot of unnoticed neighbours, with incredible lifestyles.`,
   			`We will try to discover a little of their lives 
               while traveling to their homes.`
		]
	}
}

export const info2Col = {
	col1:{
		title: "Sustainable Tourism",
		subtitle: "Not all tourism is good for earth!",
		description: [
			`Travel is very good and important, but a massive amount of people 
			flocking to a small destination generally leads to damage to the environment.`,
			`We want to help to protect these areas, and we believe that with diversification 
			 and accommodations that protect the environment, we can reach the point where tourism
			  will be helping the world.`
	    ]
	},
	col2: {
		title: "Protect the animals",
		subtitle: "A lot of animals are disappearing next to us!",
		description: [
				`Every day, more and more spices are disappearing. 
				The lands are being taken over, by cities, 
				factories and farms.`,
				`We will try to give voice to them. 
				Next to you maybe you have a colony of flamingos, seals, or turtles; 
				however, with increased human exploitation, 
				their habitat is being reduced day by day.`,
			]
	}
}