import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';


import cn from 'classnames';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


import NatureButton from '../../displays/NatureButton';

import {activities} from '../../../content/activities';

import {DOMAIN_CONTENT} from '../../../constants/constants';

const styles = ({ spacing,breakpoints,palette }: Theme) => ({
   root: {
      position: "relative"
   },
   legend:{
      position: "absolute",
      top: 0,
      left: 0,
      margin: 0,
      display: "flex",
      alignItems: "left",
      justifyContent: "center",
      textAlign: "left",
      height: "100%",
      minHeight: "100%",
      width: "100%",
      flexDirection: "column",

      paddingLeft: spacing(6),
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(12),
      },
      [breakpoints.up('md')]: {
        paddingLeft: spacing(24),
      },
    },
    line:{
      textShadow: "0px 1px 0px rgba(0,0,0,0.4),0px 18px 23px rgba(0,0,0,0.1);",
    },
    line1:{
      pointerEvents: "auto",
      margin: 0,
      padding: 0,
      fontWeight: 300,
      color: palette.common.white,
      fontSmoothing: "antialiased",
      fontSize: "19px",
      [breakpoints.up('sm')]: {
        fontSize: '37px',
      },
      [breakpoints.up('md')]: {
        fontSize: '60px',
      },
   },
   line2:{      
   	margin: 0,
      padding: 0,
      color: palette.common.white,      
      fontSmoothing: "antialiased",
      fontWeight: 900,     

      fontSize: "27px",
      [breakpoints.up('md')]: {
        fontSize: '120px',
      },
   },
   line3:{
   	  margin: 0,
      padding: 0,
      color: palette.common.white,
      fontSmoothing: "antialiased",
      
      fontWeight: 200,
      fontSize: "27px",
      [breakpoints.up('sm')]: {
        fontSize: '37px',
      },
      [breakpoints.up('md')]: {
        fontSize: '60px',
      },
   },
   buttonContainer:{
    	marginTop: spacing(2),
   }
});

const Carouselo = (props) => {
	const { classes } = props
   const { t ,i18n} = useTranslation();
   const lng = i18n.language

   /*** UNIFICAR CON EL DE ACTIVIDADES ***/
   const getLink = ( route ) => {
      if( i18n.language === "en"){
         return t("routes."+ route)
      }else{
         return "/"+ i18n.language +"/"+ t("routes."+ route)
      }
   }
   const slides = [
      {
         key: "izmir-bird-paradise",
         img: "flamencos-7.jpeg",
      },
      {
         key: "iztuzu-beach",
         img: "iztuzu-cover.jpeg",
      },
      /*{
         key: "kusadasi-walking-tours",
         img: "iztuzu-cover.jpg",
      },*/
   ]

   const carouselSrc = DOMAIN_CONTENT +"images/activities/"

   return  <Carousel showThumbs={false} showArrows={true} infiniteLoop={true} 
      transitionTime={1000} autoPlay={true} showStatus={false} className={classes.root}>
         {slides.map( s => {
            const activity = activities.filter( f => (f.t && f.key === s.key) )[0]
            const activityUrl = getLink("activity") +"/"+ activity.t[lng].url;
            
            return <div key={"carousel-"+ activityUrl}>
                <img className={classes.slideImg}
                  alt={t("home.carousel."+ s.key +".line2")}
                  srcSet={carouselSrc + s.key +"/"+ s.img +" 1024w,"
                      + carouselSrc + s.key +"/"+ s.img +"800w,"
                      + carouselSrc + s.key +"/"+ s.img +" 500w"} />

                    <div className={cn(classes.legend)}>
                        <Typography variant="h3" gutterBottom className={classes.line1}>
                          {t("home.carousel."+ s.key +".line1")}
                        </Typography>
                        <Typography variant="h3" gutterBottom className={classes.line2}>
                          {t("home.carousel."+ s.key +".line2")}
                        </Typography>
                        <Typography variant="h3" gutterBottom className={classes.line3}>
                          {t("home.carousel."+ s.key +".line3")}
                        </Typography>
                        <div className={classes.buttonContainer}>
                          <NatureButton contained light big 
                              url={activityUrl} 
                              label={t("general.learnMore")} />
                        </div>
                    </div>
               </div>
            })
      }         
      </Carousel>
}

export default withStyles(styles)(Carouselo);


/*<div>
          <img className={classes.slideImg}
            alt="Mediterranean Monk Seal"
            srcSet={carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg 1024w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-800.jpg 800w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg 500w"} />

              <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={classes.line1}>
                    {t("home.carousel.izmirFlamingo.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line2}>
                    {t("home.carousel.izmirFlamingo.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line3}>
                    {t("home.carousel.izmirFlamingo.line3")}
                  </Typography>
                  <div className={classes.buttonContainer}>
                    <NatureButton contained light big url="/animals/mediterranean-monk-seal" label={t("general.learnMore")} />
                  </div>
              </div>
         </div>
         <div>
          <img className={classes.slideImg}
            alt="Mediterranean Monk Seal"
            srcSet={carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg 1024w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-800.jpg 800w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg 500w"} />

              <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={classes.line1}>
                    {t("home.carousel.iztuzuTurtle.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line2}>
                    {t("home.carousel.iztuzuTurtle.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line3}>
                    {t("home.carousel.iztuzuTurtle.line3")}
                  </Typography>
                  <div className={classes.buttonContainer}>
                    <NatureButton contained light big url="/animals/mediterranean-monk-seal" label={t("general.learnMore")} />
                  </div>
              </div>
         </div>
         <div>
          <img className={classes.slideImg}
            alt="Mediterranean Monk Seal"
            srcSet={carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg 1024w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-800.jpg 800w,"
                + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg 500w"} />

              <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={classes.line1}>
                    {t("home.carousel.kusadasiTour.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line2}>
                    {t("home.carousel.kusadasiTour.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line3}>
                    {t("home.carousel.kusadasiTour.line3")}
                  </Typography>
                  <div className={classes.buttonContainer}>
                    <NatureButton contained light big url="/animals/mediterranean-monk-seal" label={t("general.learnMore")} />
                  </div>
              </div>
         </div>

         <div>
            <img className={classes.slideImg}
            alt="Mediterranean Monk Seal"
               srcSet={carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg 1024w,"
               + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-800.jpg 800w,"
               + carouselSrc +"mediterranean-monk-seal/carousel-mediterranean-monk-seal-500.jpg 500w"} />

              <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={classes.line1}>
                    {t("home.carousel.seal.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line2}>
                    {t("home.carousel.seal.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line3}>
                    {t("home.carousel.seal.line3")}
                  </Typography>
                  <div className={classes.buttonContainer}>
                    <NatureButton contained light big url="/animals/mediterranean-monk-seal" label={t("general.learnMore")} />
                  </div>
              </div>
         </div>
         <div>
               <img className={classes.slideImg}
                  alt="Caretta Caretta"
                  srcSet={carouselSrc +"caretta-caretta/carousel-caretta-1024.jpg 1024w,"
                     + carouselSrc +"caretta-caretta/carousel-caretta-800.jpg 800w,"
                     + carouselSrc +"caretta-caretta/carousel-caretta-500.jpg 500w"} />
               <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={cn(classes.line,classes.line1)}>
                    {t("home.carousel.caretta.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={cn(classes.line,classes.line2)}>
                    {t("home.carousel.caretta.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={cn(classes.line,classes.line3)}>
                    {t("home.carousel.caretta.line3")}
                  </Typography>
               </div>
         </div>

          <div>
               <img className={classes.slideImg}
                alt="Northern Bald Ibis"
                  srcSet={carouselSrc +"northern-bald-ibis/carousel-northern-bald-ibis-1024.jpg 1024w,"
                     + carouselSrc +"northern-bald-ibis/carousel-northern-bald-ibis-800.jpg 800w,"
                     + carouselSrc +"northern-bald-ibis/carousel-northern-bald-ibis-500.jpg 500w"} />
               <div className={cn(classes.legend)}>
                  <Typography variant="h3" gutterBottom className={classes.line1}>
                    {t("home.carousel.ibis.line1")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line2}>
                    {t("home.carousel.ibis.line2")}
                  </Typography>
                  <Typography variant="h3" gutterBottom className={classes.line3}>
                    {t("home.carousel.ibis.line3")}
                  </Typography>
               </div>
          </div>*/