import React from 'react';

import {Landscape,DirectionsWalk,AccountBalance,
	DirectionsBike, CardTravel, Deck} from '@material-ui/icons/';

import {iztuzuEn} from "./en/iztuzu-beach"
import {iztuzuEs} from "./es/iztuzu-beach"

import {izmirBirdParadiseEn} from "./en/izmir-bird-paradise"
import {izmirBirdParadiseEs} from "./es/izmir-bird-paradise"

import {ekodosdCenterEn} from "./en/ekodosd-center"
import {ekodosdCenterEs} from "./es/ekodosd-center"

import {iztuzuVolunteerEn} from "./en/iztuzu-volunteer"
import {iztuzuVolunteerEs} from "./es/iztuzu-volunteer"

import {izmirFocaEn} from "./en/izmir-foca"
import {izmirFocaEs} from "./es/izmir-foca"

import {kusadasiWalkingTourEn} from "./en/kusadasi-walking-tour"
import {kusadasiWalkingTourEs} from "./es/kusadasi-walking-tour"

import {phrygianWayEs} from "./es/phrygian-way"

import {DOMAIN_CONTENT} from '../constants/constants';


export function getActivityIcon(type) {
	let icon = ""
	
	switch( type ){
		case 'scenary':
			icon = <Landscape />
			break;
		case 'walk':
			icon = <DirectionsWalk />
			break;
		case 'museum':
			icon = <AccountBalance />
			break;
		case 'bicycle':
			icon = <DirectionsBike />
			break;
		case 'tour':
			icon = <CardTravel />
			break;
		case 'volunteer':
			icon = <Deck />
			break;
		default:
			icon = ""
			break;
	}

	return icon
}

export const activities = [
	{
		key: "phrygian-way",
		type: ["scenary","walk","museum","bicycle"],		
		image: "yazilikaya-moon.jpeg",
		cover: "yazilikaya-moon.jpeg",
		coordinates: [39.200909, 30.713889],
		price: 0,
		t:{
			es: {
				title: "La ruta de los Frigios",
				url: "la-ruta-de-los-frigios",
				short: [`Viaja al pasado con este recorrido por el centro de Turquía siguiendo las carreteras Frigias
				de más de 3000 años de antigüedad.`],
				content: phrygianWayEs,
				location: "Yazilikaya",
				country: "Turquía",
				addressTitle: "Ankara - Kuthaya - Eskisehir - Afyon",
				//address1: "Iztuzu Beach",
				address2: "",
				dates: "Todos los días",
				time: "Cualquier hora",	
			},
			
		},
	},
	{
		key: "iztuzu-beach",
		type: ["scenary","walk","museum"],		
		image: "iztuzu-caretta-sea-turtle-center.jpg",
		cover: "header.jpeg",
		coordinates: [36.795129, 28.616219],
		price: 0,
		contactLink: "http://www.dekamer.org.tr/contact.html",			
		t:{
			es: {
				title: "Iztuzu, la playa de las tortugas",
				url: "iztuzu-playa-de-las-tortugas",
				short: [`Camina y báñate por una de las más maravillosas playas de Turquía. 
					Aprende más sobre las tortugas marinas en el centro de Información 
					y visita el hospital de tortugas.`],
				content: iztuzuEs,
				location: "Dalyan",
				country: "Turquía",
				addressTitle: "Iztuzu Beach",
				//address1: "Iztuzu Beach",
				address2: "Gökbel / Dalyan",
				dates: "Todos los días",
				time: "8:00 - 20:00",	
			},
			en: {
				title: "Iztuzu Beach & Sea Turtle Center",
				url: "iztuzu-beach-sea-turtle-center",
				short: [`Walk along one of the most beautiful beaches of Turkey 
				and visit the Sea Turtle Rescue Center`],
				content: iztuzuEn,				
				location: "Dalyan",
				country: "Turkey",
				addressTitle: "Iztuzu Beach",
				//address1: "Iztuzu Beach",
				address2: "Gökbel / Dalyan",
				dates: "Everyday",
				time: "8am - 8pm",	
			}
		},
	},
	{
		key: "izmir-bird-paradise",
		type: ["scenary","bicycle"],
		image: "ride-bicycle-izmir-bird-paradise.jpg",
		cover: "header.jpeg",
		price: 0,
		coordinates: [38.534160, 26.897918],
		t: {
			es: {
				title: "Santuario de Aves en Izmir",
				url: "izmir-flamencos-y-santuario-aves",
				short: ["Disfruta de un bonito paseo en bicicleta junto a centenares de flamencos y otras aves marinas."],
				content: izmirBirdParadiseEs,				
				location: "Izmir",
				country: "Turquía",
				
				addressTitle: "İzmir Bird Paradise / İzmir Kuş Cenneti ",
				address1: "Süzbeyli, 35665",
				address2: "Menemen / İzmir",
				dates: "Todos los días",
				time: ""
			},
			en: {
				title: "Izmir Bird Paradise",
				url: "izmir-flamingo-bird-paradise",				
				short: [`Just outside Izmir, there is a rest place for thousands of flamingos
				 and pelicans with a fantastic bike way next to it.`],
				content: izmirBirdParadiseEn,				
				location: "Izmir",
				country: "Turkey",
				
				addressTitle: "İzmir Bird Paradise / İzmir Kuş Cenneti ",
				address1: "Süzbeyli, 35665",
				address2: "Menemen / İzmir",
				dates: "Everyday",
				time: ""
			}
		},
	},
	{
		key: "ekodosd-center",
		type: ["museum"],
		image: "ekodosd-kusadasi.jpg",
		cover: "header.jpeg",
		price: 0,
		coordinates: [37.861268, 27.258693],	
		location: "Kusadasi",
		country: "Turkey",
		t: {
			es: {
				title: "Centro de Naturaleza en Kusadasi",
				url: "ekodosd-centro-de-naturaleza",
				short: [`Visita el centro de Naturaleza de Kusadasi, conoce los animales que habitan la región 
				y infórmate de las actividades que tienen organizadas para los siguientes días!`],
				content: ekodosdCenterEs,				
				location: "Kusadasi",
				country: "Turquía",
				
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "De Lunes a Viernes",
				time: "9:00 - 18:00"
			},
			en: {
				title: "Izmir Bird Paradise",
				url: "izmir-flamingo-bird-paradise",				
				short: ["Visit the cultural and wildlife center of Ekodosd, in the center of Kusadasi."],
				content: ekodosdCenterEn,				
				location: "Kusadasi",
				country: "Turkey",
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "Monday to Friday",
				time: "9AM - 6PM"
			}
		},		
		contactLink: "https://ekodosd.org/index.php/letiim",		
	},
	{
		key: "kusadasi-walking-tours",
		type: ["scenary","walk"],
		image: "kusadasi-walking-tours.jpg",
		cover: "kusadasi-dilek.jpg",		
		email: "hector@naturetravel.life",
		location: "Kusadasi",
		coordinates: [37.863646, 27.248033],
		addressTitle: "EKODOSD",
		address1: "Camikebir Mahallesi. Şafak sokak, 7",
		address2: "KUŞADASI / AYDIN",	
		country: "Turkey",
		dates: "Weekends",
		t: {
			es: {
				title: "Paseos por la naturaleza en Kusadasi",
				url: "kusadasi-paseos-naturaleza",
				short: [`Visita el centro de Naturaleza de Kusadasi, conoce los animales que habitan la región 
				y infórmate de las actividades que tienen organizadas para los siguientes días!`],
				content: kusadasiWalkingTourEs,				
				location: "Kusadasi",
				country: "Turquía",
				
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "De Lunes a Viernes",
				time: "9:00 - 18:00",
				credits: [
					{
						type: "logo",
						url: DOMAIN_CONTENT +"images/logos/ekodos-logo.png?width=100"
					},
					{
						type: "html",
						content: "Todas las fotos pertenecen a EKODOSD"
					}
				]
			},
			en: {
				title: "Izmir Bird Paradise",
				url: "izmir-flamingo-bird-paradise",				
				short: ["Visit the cultural and wildlife center of Ekodosd, in the center of Kusadasi."],
				content: kusadasiWalkingTourEn,
				location: "Kusadasi",
				country: "Turkey",
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "Monday to Friday",
				time: "9AM - 6PM"
			}
		},	
		contactLink: "https://ekodosd.org/index.php/letiim",
		price: "70 - 100 TL"
	},
	{
		key: "iztuzu-turtle-volunteer",
		type: ["volunteer"],
		image: "volunteering-sea-turtle-dalyan.jpg",
		cover: "header.jpeg",
		link: "https://www.dekamer.org.tr/volunteer.html",
		location: "Dalyan",
		country: "Turkey",
		coordinates: [36.776391, 28.631606],
		addressTitle: "DEKAMER (Sea Turtle Rescue Centre)",
		address1: "Iztuzu Beach",
		address2: "Gökbel / Dalyan",
		dates: "Everyday",
		contactLink: "http://www.dekamer.org.tr/volunteer.html",	
		price: "200€ / mes",
		t: {
			es: {
				title: "Voluntariado con tortugas marinas",
				url: "iztuzu-tortuga-marina-voluntariado",
				short: [`Trabaja de voluntario para ayudar a las tortugas marinas en una de las mejores playas de Turquía.`],
				content: iztuzuVolunteerEs,				
				location: "Dalyan",
				country: "Turquía",
				
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "Todos los dias",
				time: ""
			},
			en: {
				title: "Volunteering with Sea Turtles",
				url: "iztuzu-sea-turtle-volunteer",				
				short: ["Work as a volunteer, to help the sea turtles in one of the best beaches of Turkey."],			
				content: iztuzuVolunteerEn,				
				location: "Kusadasi",
				country: "Turkey",
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "Monday to Friday",
				time: "9AM - 6PM"
			}
		},	
	},
	{
		key: "monk-seal-foca",
		type: ["museum","scenary"],
		image: "monk-seal-information-center.jpg",
		cover: "cover-seal.jpg",
		animal: ['monk-seal'],		
		location: "Foça",
		contactLink: "https://sadafag.org/en/contact-us/",
		country: "Turkey",
		coordinates: [38.666786, 26.753817],
		addressTitle: "Halk Kutuphanesi / Public Library",
		address1: "Atatürk, D:2 No:1, Avni Arbaş Sk.",
		address2: "35680 Foça / İzmir",
		dates: "Monday to Friday",
		time: "10am - 12:30pm",
		price: 0,
		t: {
			es: {
				title: "Ver una Foca Monje, el gran reto",
				url: "ver-foca-monje",
				short: [`Intenta encontrar una de las 100 focas monje que viven en Turquía`],
				content: izmirFocaEs,				
				location: "Foça",
				country: "Turquía",
				
				addressTitle: "Halk Kutuphanesi / Biblioteca Pública",
				address1: "Atatürk, D:2 No:1, Avni Arbaş Sk.",
				address2: "35680 Foça / İzmir",				
				dates: "De Lunes a Viernes",
				time: "10 - 12:30"
			},
			en: {
				title: "Volunteering with Sea Turtles",
				url: "iztuzu-sea-turtle-volunteer",				
				short: ["Work as a volunteer, to help the sea turtles in one of the best beaches of Turkey."],				
				content: izmirFocaEn,				
				location: "Kusadasi",
				country: "Turkey",
				addressTitle: "EKODOSD",
				address1: "Camikebir Mahallesi. Şafak sokak, 7",
				address2: "KUŞADASI / AYDIN",				
				dates: "Monday to Friday",
				time: "9AM - 6PM"
			}
		},	
	},
]