import React from 'react';

import cn from 'classnames';

import { compose } from 'recompose'

import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

//PersonOutlineOutlined,Menu,
//PetsOutlined,ExploreOutlined
import {HomeOutlined,FaceOutlined,AlternateEmail} from '@material-ui/icons/';

import ScrollProgress from '../animations/ScrollProgress';


import Logo from '../../images/logo-white.png';
import LogoText from '../../images/logo-text.png';


const styles = ({ palette, spacing,breakpoints,shadows }: Theme) => ({
  	root: {
      	//backgroundColor: fade(palette.primary.main,0.5),
  		fontFamily: 'Noto Sans',  	
  		margin: 0,	
  		padding: 0,
      	position: "relative",
  		zIndex: 9,
  		width: "100%",
  		boxShadow: shadows[3],
  	},
  	appBar: {
  		backgroundColor: 'transparent',
  		boxShadow: 'none',
  		margin: 0,	
  		padding: 0,
  	},
  	toolBar:{
  		display: 'flex',
  		justifyContent: 'space-between',
  		margin: 0,	
  		padding: 0,
  	},
  	logo:{
  		height: spacing(10)
  	},
  	menu:{
  		display: 'flex',
  		flexDirection: 'row',
  		margin: 0,
  		padding: 0,
  		textTransform: "uppercase",
  	},
  	listItem:{
  		color: "#FFF",
  		paddingTop: spacing(4),
  		paddingBottom: spacing(4),		
  		letterSpacing: '1px',
  		transition: "color 300ms, background-color 300ms, opacity 300ms",
  		fontSmoothing: "antialiased",
  		fontWeight: "bold",
  		whiteSpace: "nowrap",
  		"&:hover": {
  			color: palette.secondary.main,
  			borderBottom: "3px solid "+ palette.secondary.main
  		}
  	},
  	listIcon:{
  		fontSize: "0.9rem",
      	fontWeight: 400,
      	lineHeight: 1.6,
  		marginRight: spacing(1)
  	},
  	buttonAction:{
  		marginLeft: spacing(3),
      	backgroundColor: palette.secondary.main,
  		borderBottom: "3px solid "+ palette.secondary.dark,
  		color: palette.secondary.contrastText,
  		"&:hover": {
  			color: palette.secondary.light,
  			backgroundColor: palette.secondary.dark,
  			borderBottom: "3px solid "+ palette.secondary.light
  		}
  	},
  	max:{
  		backgroundColor: palette.primary.main,
  	},
  	min:{
		backgroundColor: fade(palette.primary.dark,.5),
		//backgroundColor: "rgba(red(palette.primary.dark),green(palette.primary.dark),blue(palette.primary.dark,.5)",		
		backdropFilter: "blur(5px)",
		position: "fixed",
		top: 0,
		
		boxShadow: "0 4px 2px -2px rgba(0,0,0,.2)",

		"& $root": {
      		backgroundColor: fade(palette.primary.main,0.5),
      		position: "relative",
  			zIndex: 999,
  			width: "100%",
  			border: "10px solid red"
  		},
		"& $logo": {
	  		height: spacing(6)
		},
		"& $listItem":{
			paddingTop: spacing(2),
  			paddingBottom: spacing(2),
		},
		"& $toolBar": {
			minHeight: 0,
		}
  	}

});



const NavTop = (props) => {
  	const {classes,t,i18n} = props
  	
  	const getLink = ( route ) => {
		if( i18n.language === "en"){
			return t("routes."+ route)
		}else{
			return "/"+ i18n.language +"/"+ t("routes."+ route)
		}
	}

 	const menu = [
 		{
 			'label': t('menu.home'),
 			'icon': <HomeOutlined className={classes.listIcon} />,
 			'url':  getLink('home'),
 			'hiddenXs': true,
 		},
 		/*{
 			'label': t('menu.animals'),
 			'icon': <PetsOutlined className={classes.listIcon}/>,
 			'url':  getLink('animals'),
 		},*/
 		{
 			'label': t('menu.about'),
 			'icon': <FaceOutlined className={classes.listIcon}/>,
 			'url':  getLink('about'),
 		},
 		{
 			'label': t('menu.contact'),
 			'icon': <AlternateEmail className={classes.listIcon}/>,
 			'url':  getLink('contact'),
 		},
 		/*{
 			'label': 'Activities',
 			'icon': <ExploreOutlined className={classes.listIcon}/>
 		},
 		{
 			'label': 'Blog',
 			'icon': <PersonOutlineOutlined className={classes.listIcon}/>
 		},*/
 	]

 	const classMin = (props.min)?classes.min:classes.max

 	let logo = LogoText
 	if( classMin ){
 		logo = Logo
 	}
 	
 	const printListItem = ( m ) => <ListItem button component="a" 
				href={m.url} className={classes.listItem} key={m.label}> 
					<Hidden smDown>
						{m.icon}
					</Hidden>
					<Typography variant="body1">{m.label}</Typography>
			</ListItem>
	const printMenu = ( m ) => {
		const list = printListItem( m )
		if( m.hiddenXs ){
			return <Hidden smDown>
				{list}
			</Hidden>
		}else{
			return list
		}
	}
/* 
<ListItem button component="a" className={cn(classes.listItem,classes.buttonAction)}>
				<Typography variant="body1" >Contact</Typography>
			</ListItem>
			*/
	const menuList= <List className={classes.menu}>
			{menu.map( (m) => printMenu(m))}
			
			
		</List>

	const scrollProgress = <Grid xs={12} item >
						<ScrollProgress />
					</Grid>
					
  	return 	<div className={cn(classes.root,classMin)}>
	  					<Grid container justify="center" className={cn(classes.menuContainer)}>  					
			  				<Grid sm={10} item >
							  	<AppBar position="static" className={classes.appBar}>
							  		<Toolbar className={classes.toolBar}>
							  			
							  			<IconButton href="/">
							  				<img className={classes.logo} alt="Nature Travel" src={logo} />
							  			</IconButton>
							      	
							      	{menuList}
							  		
							  		</Toolbar>
								</AppBar>						
							</Grid>
							{scrollProgress}
						</Grid>					
			</div>
}

const enhance = compose(
  withTranslation(),
  withStyles(styles)
)

export default enhance(NavTop)

