import React from 'react';

import cn from 'classnames';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';

import {Facebook,Instagram} from '@material-ui/icons/';

import ContactForm from '../../base/ContactForm';

import { useTranslation } from 'react-i18next';

import NatureButton from '../../displays/NatureButton';

const styles = ({ palette, spacing }: Theme) => ({
	root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: palette.background.paper,
   },   
   contactRow:{
      //color: palette.text.light,
      paddingTop: spacing(8),
      paddingBottom: spacing(8),
   },  
   subtitle:{
      color: palette.secondary.dark,
      marginBottom: spacing(4)
   },
   contactForm:{
      marginBottom: spacing(8)
   },
   contactButtons:{
      display: "flex",
      justifyContent: 'space-around',
      marginTop: spacing(4),
   },
   buttonContent: {
      display: "flex",
      alignItems: "center"
   },
   buttonIcon:{
      marginRight: spacing(1),
   }
});


const Contact = (props) => {
	const { classes } = props
   const { t } = useTranslation();

	return <div className={classes.root}>
      <Grid container justify="center" className={cn(classes.contactRow)}>  
         <Grid item xs={10} className={cn(classes.main)} >
            <Typography component="h3" variant="h2" className={classes.subtitle} >{t("contact.contact")}</Typography>
            <Typography variant="h5">{t("contact.textContact")}</Typography>
            <div className={classes.contactForm}>
               <ContactForm />
            </div>
            
            <Typography variant="h5">{t("contact.textSocial")}</Typography>
            
            <div className={classes.contactButtons}>               
               <NatureButton url="https://www.facebook.com/naturetravel10" contained external label={<span className={classes.buttonContent}><Facebook className={classes.buttonIcon} /> {t("contact.buttonFacebook")}</span>} />
               <NatureButton url="https://www.instagram.com/naturetravel_life" contained external label={<span className={classes.buttonContent}><Instagram className={classes.buttonIcon} /> {t("contact.buttonInstagram")}</span>} />
            </div>
         </Grid>
      </Grid>
   </div>
}

export default withStyles(styles)(Contact);