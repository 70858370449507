import * as React from "react";

import { withStyles } from '@material-ui/core/styles'

import { motion, useViewportScroll, useTransform } from "framer-motion";


const styles = ({ spacing,palette }: Theme) => ({
  	root: {
        backgroundColor: palette.primary.dark,
        width: "100%",
  	},
  	container:{
      	backgroundColor: palette.primary.main,    
      	width: "100%",
      	position: "relative",
  	},
  	item: {
  		backgroundColor: "white",
  		height: spacing( 0.5 ),
  	}

});

const ScrollProgress = ( props ) => {
	const { scrollYProgress } = useViewportScroll();
	const scale = useTransform(scrollYProgress, [0, 0], [1, 1]);
	const {classes} = props

  	return  <div className={classes.root}>
	    <motion.div
	        className={classes.container}
	        style={{
	          scale
	        }} >
        
	        <motion.div
	          className={classes.item}
	          style={{
	            scaleX: scrollYProgress
	          }}>	         	
	        </motion.div>
      	</motion.div>
    </div>
};


export default withStyles(styles)(ScrollProgress)