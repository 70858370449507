import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';


const styles = ({ spacing }: Theme) => ({
  	root:{
  		textAlign: "center",
  		minHeight: spacing(20),
  		padding: spacing( 6 ),
    	fontSmoothing: "antialiased",
    	color: "#FFF",
  	},
    icon: {
      width: spacing( 10 ),
      marginBottom: spacing( 4 ),
    },
    title: {
      marginBottom: spacing( 3 ),
    },
    p: {
      marginBottom: spacing( 2 ),
    }
});

const BoxIcon = (props) => {
  const {classes} = props
  
  //      <Button>Learn More</Button>

  return <div className={classes.root} >
			<img alt="" className={classes.icon} src={props.icon} />
      <Typography component="h4" variant="h4" className={classes.title}>{props.title}</Typography> 
  	</div>
}

export default withStyles(styles)(BoxIcon)