export const izmirBirdParadiseEn = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`El sud oeste de turquía acoge de una de las playas más bonitas que he visto, Iztuzu. 
		Rodeada de acantilados rebosantes de pinos, la arenosa playa se estira entre el mar y la laguna. 
		Este pequeño paraíso de naturaleza es también la casa de la tortuga boba, 
		donde cada año cientos de ellas van a sus orillas para poner sus huevos.`]
	},
	{
		type: "image",
		src: "iztuzu-about",
		label: "Vistas de la playa"
	},
	{
		type: "p",
		content: [`No hay apenas construcciones, solo unos pequeños restaurantes , algunas zonas de tumbonas y sombrillas para los bañistas repartidas por la playa 
		, y 4 km de fina arena hacen de la playa de Iztuzu el perfecto ejemplo de como encontrar el equilibrio entre el turismo y la protección de la naturaleza.`]
	},
	{
		type: "image",
		src: "iztuzu-sunbeds",
		label: "Tumbonas"
	},
	{
		type: "p",
		content: [`Sus horarios son restringidos, solo se puede acceder a la playa de 
		8 de la mañana a 8 de la tarde, ya que por la noche, cuando se van los turistas 
		vienen los más importantes huespédes, las tortugas. A principios de verano, centenares de ellas 
		llegan a las arenas de Iztuzu para poner sus huevos.`]
	},
	{
		type: "p",
		content: [`Pasear por el magnifico entorno de Iztuzu, es una experiencia única. 
		Mientras se anda por la arena frecuentemente se puede encontrar el rasto de alguna 
		tortuga que ha salido del agua para hacer el nido, o las cascaras de huevos rotos.
		Tras la deliciosa caminada uno puede recuperarse bebiendo un te o comiendo un poco 
		en los restaurantes que hay a ambos extremos de la playa, mientras se va dando un chapuzón 
		de vez en cuando para refrescarse del calor`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "iztuzu-walk-1",title: "Magnifica playa de Iztuzu",cols: 2,rows: 2},
			{src: "iztuzu-walk-2",title: "Magnifica playa de Iztuzu",cols: 2,rows: 2},
			{src: "iztuzu-walk-3"},
		],
	},
	{
		type: "title",
		content: "Centro de Información y Hospital de tortugas marinas"
	},
	{
		type: "p",
		content: [`Una visita a Iztuzu no está completa sin antes aprender un poco sobre las tortugas en DEKAMER, 
		el centro de estudio y protección de las tortugas que hay al inicio de la playa.
		En el centro tienen un pequeño museo donde los trabajadores te explican la situación de las tortugas, 
		y sus peligros. Después se puede visitar el hospital y conocer las tristes historias de sus pacientes. 
		La mayoría de tortugas han sido heridas por culpa de los humanos, especialmente las lanchas a motor o las herramientas de pesca.`]
	},
	{
		type: "gallery",
		gallery: [
			{src: "iztuzu-museum"},
			{src: "iztuzu-hospital-1"},
			{src: "iztuzu-hospital-2"},
		],
	},
	{
		type: "p",
		content: [`La vida de la tortuga joven está plagada de peligros, solo 1 huevo de  cada 1000, 
		consigue llegar a la edad adulta, donde después de 25 años de viajes y aventuras volverá a la 
		playa donde nació para poner los huevos.  Es muy importante proteger los nidos,
		 para que el numero máximo de bebes consigan llegar a las aguas.  
		 Los trabajadores de DEKAMER cada noche patrullan ininterrumpidamente la playa. 
		 Cuando encuentran una tortuga haciendo el nido, inmediatamente lo protejen con unas jaulas 
		 para que los zorritos y gaviotas no puedan comerse los huevos y lo marcan debidamente lo 
		 para que los humanos no estropeen los nidos.`]
	},
	{
		type: "gallery",
		gallery: [
			{src: "iztuzu-cage-1", cols: 3, rows: 2},
			{src: "iztuzu-cage-2"},
			{src: "iztuzu-cage-3"},
			{src: "iztuzu-cage-4"},
		],
	},
	{
		type: "title",
		content: "Como la playa fué salvada de la construcción hotelera"
	},
	{
		type: "p",
		content: [`Hoy en dia Iztuzu parece que está a salvo de la ola massiva de apartamentos y 
		hoteles que ha assolado las costas turcas,  pero en los años 80 estuvo a 
		punto de ser destruido. La pressión del turismo estaba poniendo en peligro la playa y 
		las tortugas que en ella anidan, hasta llegar al punto en que una compañía alemana,
		ya había empezado a construir un gran complejo hotelero. 
		Por suerte unos años atrás una chica inglesa, June Haimoff, se había enamorado de la 
		playa y cada año veraneaba en una cabaña de madera en la misma playa. Al ver el 
		peligro que acechaba a las tortugas empezó a movilizar a la gente para que 
		protegieran el entorno y las tortugas. Tras mucho esfuerzo y muchas batallas, c
		on la ayuda de la gente local, y algunas ONG,  consiguieron que la playa fuera 
		un espacio protegido y no se pudiera construir en él. La tarea de June, 
		no terminó ahí, ya que desde entonces se ha dedicado incansablemente
		 a la protección de las tortugas.`]
	},
	{
		type: "title",
		content: "Como llegar?"
	},
	{
		type: "p",
		content: [`Para llegar a Iztuzu, lo más fácil es ir en coche o usar el 
		transporte público.
		Si decides ir en coche, puedes aparcar justo al inicio de la playa, donde hay un parking especialmente para los turistas. 
		Para los que vayan en transporte público, desde el centro de Dalyan 
		hay minibuses frecuentes que van a la playa, o también puedes se puede 
		ir en un barco turístico que salen desde el rio de Dalyan, cerca de los espectaculares monumentos de Kaunos. 
		Los barcos empieza a las 9:30 de la mañana y retornan a la ciudad cada hora. El precio es de 25 TL ida y vuelta.`]
	},
	{
		type: "gallery",
		gallery: [
			{src: "dalyan"},
			{src: "kaunos"},
			{src: "boats"},
		],
	},
	{
		type: "title",
		content: "Horarios"
	},
	{
		type: "p",
		content: [`La playa está abierta cada día de 8 de la mañana a 8 de la noche.`]
	},
]