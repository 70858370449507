//import { createMuiTheme } from '@material-ui/core/styles';
import { createMuiTheme,responsiveFontSizes } from '@material-ui/core/styles'

//const breakpoints = createBreakpoints({})

/*
  <color name="primaryTextColor">#000000</color>
  <color name="secondaryTextColor">#ffffff</color>
*/


const theme1 = createMuiTheme({
  	palette: {
      accent: { 
        color: '#facc2e',
      },
      accentDark: {
        color: '#5dc269'
      },
      accentGrey: {
        color: '#b0b0b0'
      },      
      primary: { 
        main: '#004c94',
        light: '#4d77c5',
        dark: '#311432',
      },
      secondary: {
        main: '#0fddaf',
        light: '#67ffe1',
        dark: '#00aa80',
      },
      text: {
        primary: '#808080',
        light: '#FFFFFF',
      },
  	},
  	spacing: 6,
   typography: {
      fontFamily: ['Lato'],
      h1:{
        	fontSize: "3.25rem",        
        	fontWeight: 800,
      },
      h2:{
        	//fontSize: "2.25rem",        
        	fontSize: "2.6rem",        
        	fontWeight: 700,
        	lineHeight: 1.3,
      },
      h3: {
        	//fontSize: "1.25rem",  
        	fontSize: "1.3rem",        
        	fontWeight: 400,
      },     
      h4:{
        	//fontSize: "1.125rem",        
        	fontSize: "1.3rem",
        	fontWeight: 800,
        	lineHeight: 1.4,
      },
      h5:{
        	fontSize: "1rem",        
        	fontWeight: 700,
        	lineHeight: 1.6,
      },
      h6:{
          //fontSize: "0.688rem",        
        	fontSize: "0.6rem",        
        	fontWeight: 700,
        	lineHeight: 2.3,
      },
      body1:{
			 //fontSize: "0.813rem", // 13 
        fontSize: "0.9rem",
        fontWeight: 400,
        lineHeight: 1.6,
      },
      body2:{
        	fontSize: "1.3rem",
        	fontWeight: 400,
        	lineHeight: 1.7,
      },
      button:{
        	fontSize: "0.75rem",
        	fontWeight: 800,
        	letterSpacing: "1.3px",
        	textTransform: "uppercase",
      },
      caption:{
        fontSize: "0.75rem",
      }

      /*h3: {
        fontSize: '1.2rem',
        [breakpoints.up('md')]: {
          fontSize: '2.4rem',
        }
      },*/
   },
});

//export const theme;
export const theme = responsiveFontSizes(theme1)