import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const styles = ({ palette, spacing }: Theme) => ({
  	root: {
      backgroundColor: palette.primary.dark,
      color: palette.primary.contrastText,
  		fontSmoothing: "antialiased",
      position: "relative",
      zIndex: 8,
  	},
   socialLinkContainer:{
      textAlign: "right",
   },
  	socialLink: {
      marginLeft: spacing(2),
      color: "rgb(206, 206, 206)",
  	},
    contactInfo: {
      paddingRight: spacing(4),
    },
});

/*  <Grid item xs={1} sm={2} style={{textAlign: "right",lineHeight: 0.9,marginBottom: "3px"}}>
              <LanguageSwitcher />
            </Grid>
*/
const SocialBar = (props) => {
  	const {classes} = props

  	return <Grid container justify="center" className={cn(classes.root)}>
  		<Grid item sm={10} className={cn(classes.main)} >
         <Grid container alignItems="center" >
         	<Grid item xs={1} sm={4} style={{lineHeight: 0.9,marginBottom: "3px"}}>
	            <Hidden smDown>
	               <Typography variant="caption" className={classes.contactInfo}>Phone : +53 806 951 83</Typography>
	               <Typography variant="caption" className={classes.contactInfo}>Email : hector@naturetravel.life</Typography>
	            </Hidden>
	         </Grid>
         	<Grid item xs={11} sm={8} className={classes.socialLinkContainer} style={{lineHeight: 0.9,marginBottom: "3px"}} >	         
             	<IconButton className={classes.socialLink} size="small"
                	href="https://www.facebook.com/Nature-Travel-112481583817335" 
                 	rel="noopener noreferrer" target="_blank">
                 	<Typography variant="caption" >
                  	<FontAwesomeIcon icon={faFacebookF} />
                 	</Typography>
             	</IconButton>
              <IconButton className={classes.socialLink} size="small"
                	href="https://www.instagram.com/naturetravel_life" 
                 	rel="noopener noreferrer" target="_blank">
                 	<Typography variant="caption" >
                  	<FontAwesomeIcon icon={faInstagram} />
                 	</Typography>
              </IconButton>              
            </Grid>
           
          </Grid>          
  		</Grid>
  </Grid>    
}

export default withStyles(styles)(SocialBar)