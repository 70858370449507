import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import TitleBox from '../displays/TitleBox';


const styles = ({ spacing }: Theme) => ({
  	root: {
  		textAlign: "center",
  		minHeight: spacing(20),
  		padding: spacing( 6 ),
    	fontSmoothing: "antialiased",
    	color: "#000",
  	},
  	list: {  	
  		margin: spacing(3),
  	},
    logo:{
      maxWidth: "100%",
    }


});

const Acknowledgments = (props) => {
  const {classes} = props
 
  return <Grid container justify="center" className={cn(classes.root)}>
		<Grid item sm={10} className={cn(classes.main)} >
			<TitleBox title="Acknowledgments" /> 
			<div className={cn(classes.list)}>
 				<img alt="sad-afag" src="https://sadafag.org/wp-content/uploads/sadafag-logo1.png"
          className={classes.logo} />
 			</div>

		</Grid>
	</Grid>
}

export default withStyles(styles)(Acknowledgments)