import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Acknowledgments from '../../base/Acknowledgments';
import ActivityList from '../../base/ActivityList';
import Typography from '@material-ui/core/Typography';

import ParallaxBox from '../../base/ParallaxBox';
import HeaderTitleBox from '../../displays/HeaderTitleBox';
import Highlights from '../../displays/Highlights';

import Info2Col from '../../base/Info2Col';

import TextCol from '../../displays/TextCol';
import BoxBigSubtitle from '../../displays/BoxBigSubtitle';

import Organization from './Organization';

const styles = ({ spacing,palette }: Theme) => ({
	textTitle:{
		marginTop: spacing(2),
		color: palette.accentDark.color
	},
	list:{
		margin: 0,
		padding: 0,
		listStyleType: "none",
      	marginTop: spacing(4),
      	"& li": {
      		marginBottom: spacing(2),
      	},
      	"& b": {
        	color: palette.accent.color,
      	}
	}

});

const Animal = (props) => {
	const { classes } = props

	const text = <div>
		<p>
			Seals still exist in the Mediterranean Sea! 
			It is quite an incredible fact if we dig a little about their history. 
			Throughout the history, humans have been doing commercial hunting of the seals, 
			especially during the Roman times and the Middle ages. 
			During the 20th century, the decreased and the persecuted seal population 
			got another enemy - the fishermen. 
			Fishermen killed the seals mercilessly when they tried to break the fishermen’s 
			fish nets for food. Still some seals managed to survive to find their homes destroyed 
			with coastal urbanizations and their water polluted.
		</p>
		<p>
			Currently, we have only around 700 fellows in the Mediterranean Sea, most 
			of which concentrated on the coasts of Greece and Turkey. Seals enjoy the open 
			beaches, lying lazily under the hot sun, and raising their families peacefully; 
			however, the huge number of humans look for the summer sun next to the sea, 
			kicking them out of their homes and forcing them to live in caves. 
			Now, instead of raising their pups on a sunny beach, the seals are 
			doing that in dangerous caves, where the strong waves during the storms 
			increase the mortality of pups.
		</p>
		<p>
			But that's not all! Nowadays, business tourism continues to damage them. 
			Scuba-diving schools organize trips to the 'seal caves', forcing them 
			to leave the few safe places that still exist for them. The seals 
			have a pup only once every one or two years. It is very dangerous 
			if they breed less due to the lack of habitat.
		</p>
		<p>
			It is quite incredible how humans can persecute and destroy a species, 
			and I do hope that while we have shown the efficiency in destroying them, 
			the same can also be displayed to protect them.
		</p>
	</div>
	
	/*const text1 = <div>
		<p>
			Seals still exist in the Mediterranean sea! 
			It is quite an incredible fact if we dig a little about their history. 
			Since a long time, humans have been doing commercial hunting of the seals, 
			especially during the Roman times and the Middle ages. 
			During the 20th century, the decreased and the persecuted seal population 
			got another enemy - the fishermen. 
			Fishermen killed the seals mercilessly when they tried breaking the 
			fishermen’s fish nets for food. Still some seals managed to survive 
			to find their homes destroyed with coastal urbanizations and their water polluted.
		</p> 
		<p>
			Currently, we have only around 700 fellows in the Mediterranean sea, most of them
			 concentrated on the coasts of Greece and Turkey. Seals enjoy the open beaches, 
			 lying lazily under the hot sun, and raising their families peacefully; 
			 however, the huge number of humans looking for the summer sun next to the sea, 
			 kick them out of their homes and force them to live in caves. Now, instead of 
			 raising their pups on a sunny beach, the seals are doing that in dangerous caves, 
			 where the strong waves during the storms increase the mortality of pups.
		</p>
		<p>
			But that's not all! Nowadays, business tourism continues to damage them. 
			Scuba-diving schools organize trips to the 'seal caves', forcing them to 
			leave the few safe places that still exist for them. The seals have a pup only 
			once every one or two years. It is very dangerous if they breed less due to the 
			lack of habitat.
		</p>
		<p>
			It is quite incredible how humans can persecute and destroy a species, 
			and I do hope that the efficiency we have shown in destroying them, 
			the same can also be displayed to protect them.
		</p>
	</div>*/

	const bgCol1 = <BoxBigSubtitle
						title="About the Monk Seal"
						subtitle="Europe’s most endangered animal"
						text={<div>
							<Typography>One of the biggest animals of Europe, 
							with an average width of 2.4 meters and a weight of 230 kgs, 
							these shy seals seem to have gone quite unnoticed by most of us.
							</Typography>
	     					<Typography>They like to have a relaxed lifestyle on quiet and 
	     					pristine waters, and like a lot of humans, have a special 
	     					appetite for fish, octopus and lobster.</Typography>

	     					<Typography>Monk Seals are polygynous, and they give birth after a period 
	     					of 11 months of pregnancy. They only give birth to a pup once every one 
	     					or two years. Since the birth rate is so low, it becomes all the more important 
	     					to protect them and their homes.</Typography>
	     				</div>}
					/>

	const bgCol2 = <BoxBigSubtitle
						title="Biggest Threats"
						subtitle="A lot of animals are disappearing just next to us!"
						text={<ul className={classes.list}>
			            		<li>
			            			<Typography>
			            				<b>Deterioration of Coastal Habitats.</b>
			            				They need wide untouched places to breed and to live. 
			            				The systematic destruction of the coast is their bigger threat.
			            			</Typography>
			            		</li>
			            		<li>
			            			<Typography>
			            				<b>Overfishing and illegal fishing.</b>
			            				Last few years, the increase of fishing activity in the seals’ areas decreased 
			            				the fish available for seals, forcing the latter to get the fish from the 
			            				fishermen's nets.
			            			</Typography>
			            		</li>
			            		<li>
			            			<Typography>
			            				<b>Deaths.</b>
			            				Fishermen kill some seals as a revenge for seals breaking their nets to eat the fish caught by them. 
			            				Also there is a big risk to the lives of the pups who die struggling by the fishing nets.
			            			</Typography>
			            		</li>
			            		<li>
			            			<Typography>
			            				<b>Disturbance in seal caves.</b> 
			            				Scuba-diving companies organize tours to the "seals' caves". That is the place for them to breed, 
			            				and this continuous disturbance forces them to abandon their houses. 
			            				There are not many quiet caves available and therefore such tours decrease their breeding.
									</Typography>
								</li>
			            </ul>}
					/>		

	const highlights = [
		{
			avatar: "https://sadafag.org/wp-content/uploads/2019/01/eved-kocero-portre-sad-afag-401x400.png",
			title: "ONLY LESS THAN 700 SEALS!",
			subtitle: "",
			description: <div>
				<p>The population is very small and they are facing a huge danger.</p>
				<p>If we do not help, mediterranean seals might get extinct in less than a generation.</p>
				</div>
		},
		{
			avatar: "https://upload.wikimedia.org/wikipedia/commons/7/79/Colonia_de_focas_monje_de_Cabo_Blanco_%281945%29.jpg",
			title: "THEIR HABITAT HAS BEEN LOST",
			subtitle: "",
			description: <div>
				<p>Our pasion to fill nature with concrete has destroyed most of their habitat.</p>
				<p>Massive tourism has forced them to leave the beaches and get shelter in remote caves.</p>
			</div>
		},
		{
			avatar: "https://instagram.fist13-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s640x640/60676701_171923663812626_2682839376513137093_n.jpg?_nc_ht=instagram.fist13-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=s_BAALFU95YAX9EQEzx&oh=651bd5cd8873396723917844619e57fa&oe=5F081408",
			title: "BREEDING CAVES IN DANGER",
			subtitle: "",
			description: <div>
					<p>Tourist boats and scuba diving tours are interfering in the seals' breeding areas and hence they are abandoning the caves.</p>
					<p>With a birth rate of one pup once every one or two years, it is very dangerous for the survival of the species, if they abandon the breeding caves.</p>
				</div>
		}
	]

	return <div>
		<HeaderTitleBox
			title="Mediterranean Monk Seal" subtitle="West of Turkey" />	

		<TextCol 
			title=""
			subtitle=""
			text={text} />
		
		<Highlights list={highlights} />

    	<ParallaxBox 
    		background="https://naturetravel.life/content/images/animals/mediterranean-monk-seal/carousel-mediterranean-monk-seal-1024.jpg">
    		<Info2Col content1={bgCol1} content2={bgCol2} />
    	</ParallaxBox>
		
		<ActivityList />		

		<Organization />	

		<Acknowledgments /> 			
		
   	</div>
}

export default withStyles(styles)(Animal);