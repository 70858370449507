/*
Paseos Naturaleza
kusadasi-nature-1
kusadasi-nature-4
kusadasi-nature-5
kusadasi-nature-10
kusadasi-nature-12
kusadasi-nature-13
kusadasi-nature-14
kusadasi-nature-15
kusadasi-nature-17
kusadasi-nature-18
kusadasi-nature-19
kusadasi-nature-29
kusadasi-nature-30
kusadasi-nature-32
kusadasi-nature-34
kusadasi-nature-35
kusadasi-nature-37
kusadasi-nature-39








Observacion aves
kusadasi-nature-9
kusadasi-nature-24
kusadasi-nature-28
kusadasi-nature-31





Botanica
kusadasi-nature-2
kusadasi-nature-20



Cultura
kusadasi-nature-6
kusadasi-nature-7
kusadasi-nature-8
kusadasi-nature-11
kusadasi-nature-16
kusadasi-nature-21
kusadasi-nature-22
kusadasi-nature-25
kusadasi-nature-27
kusadasi-nature-33
kusadasi-nature-36
kusadasi-nature-38





Otros
kusadasi-nature-3
kusadasi-nature-23
kusadasi-nature-26

*/

export const kusadasiWalkingTourEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`Si estas en Kusadasi, no dejes escapar la oportunidad de descubrir los alrededores de la ciudad en compañía de EKODOSD que cada 15 días, 
		durante el fin de semana organiza diferentes actividades para descubrir la naturaleza.`]
	},
	{
		type: "image",
		src: "kusadasi-nature-28.jpeg",
		label: "Carril Bici"
	},
	{
		type: "title",
		content: "Paseos en la naturaleza"
	},
	{
		type: "p",
		content: [`¿Qué mejor plan puede haber para el fin de semana que descubrir los increíbles paisajes alrededor de Kusadasi? Caminar por la península de Dilek, 
		nadar en tranquilas playas, o visitar diferentes montañas, rios y lagos. 
		Además siempre con los guías de EKODOSD, que os enseñaran la historia de los 
		lugares que se visitan, y sobretodo aprendiendo sobre la fauna y flora que las habita.`,
		`El objetivo de las caminatas no es de competición ni llegar pronto al destino, sino disfrutar, evitando los riesgos, 
		conociendo la flora, fauna, historia y cultura de la región visitada.`]
	},
	{
		type: "image",
		src: "kusadasi-nature-32.jpeg",
		label: "Danzas Tradicionales cerca de Kusdasi"
	},
	{	
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "kusadasi-nature-1.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-4.jpeg",title: "",cols: 1,rows: 1},			
			{src: "kusadasi-nature-10.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-12.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-13.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-14.jpeg",title: "",cols: 1,rows: 1},			
		],
	},	
	{
		type: "image",
		src: "kusadasi-nature-5.jpeg",
		label: "Paseos en la naturaleza"
	},
	{	
		type: "gallery",
		cols: 4,
		gallery: [			
			{src: "kusadasi-nature-15.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-17.jpeg",title: "",cols: 1,rows: 1},
			//{src: "kusadasi-nature-18.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-19.jpeg",title: "",cols: 1,rows: 1},			
			{src: "kusadasi-nature-30.jpeg",title: "",cols: 1,rows: 1},
			//{src: "kusadasi-nature-34.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-35.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-37.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-39.jpeg",title: "",cols: 1,rows: 1}
		],
	},	
	{
		type: "title",
		content: "Observación de Aves"
	},	
	{
		type: "p",
		content: [`Cerca de Kusadasi hay importantes humedales, Delta de Buyuk Menderes, Lago bafa, lago Azap..., 
		que acogen a una gran variedad de aves, como pelícanos, cormoranes, cernícalos o 
		el águila de cola blanca.`,
		`La observación de aves se centra en aprender sobre los diferentes 
		pájaros que habitan la región e intentar conseguir alguna bonita foto de ellos.`]
	},	
	{
		type: "image",
		src: "kusadasi-nature-31.jpeg",
		label: "Flamencos en Kusadasi"
	},
	{	
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "kusadasi-nature-9.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-24.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-28.jpeg",title: "",cols: 1,rows: 1}
			//{src: "kusadasi-nature-31.jpeg",title: "",cols: 1,rows: 1}
		],
	},	
	{
		type: "title",
		content: "Botánica"
	},
	{
		type: "p",
		content: [`Algunas de las actividades organizadas se dedican a buscar arboles monumentales, 
		en las montañas y lagos de los alrededores para que sean protegidos por las instituciones 
		de patrimonio cultural y natural de la región. `
		]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "kusadasi-nature-2.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-20.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-29.jpeg",title: "",cols: 1,rows: 1}		
		],
	},	
	{
		type: "title",
		content: "Actividades Culturales"
	},
	{
		type: "p",
		content: [`EKODOSD también se encarga de promocionar antiguas tradiciones de los alrededores de Kusdasi. 
		Visitar antiguos castillos, cuevas prehistóricas, o presenciar danzas tradicionales, 
		son solo algunas de las actividades culturales que puedes disfrutar.`
		]
	},
	{
		type: "image",
		src: "kusadasi-nature-8.jpeg",
		label: "Danzas Tradicionales cerca de Kusdasi"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "kusadasi-nature-6.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-7.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-11.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-16.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-21.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-22.jpeg",title: "",cols: 1,rows: 1},
			//{src: "kusadasi-nature-25.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-27.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-33.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-36.jpeg",title: "",cols: 1,rows: 1},
			{src: "kusadasi-nature-38.jpeg",title: "",cols: 1,rows: 1}
		],
	},	

	{
		type: "title",
		content: "¿Cómo ir?"
	},
	{
		type: "p",
		content: [`Normalmente dependiendo de la gente que se apunte a las actividades 
		alquilan un minibus o se organizan diversos coches para ir al lugar. 
		Solamente has de ponerte en contacto con EKODOSD y ellos os van a indicar 
		el punto de encuentro, que es en el centro de la ciudad.`
		]
	},
	{
		type: "image",
		src: "kusadasi-dilek.jpg",
		label: "Dilek Peninsula"
	},
	{
		type: "title",
		content: "¿Cuánto cuesta?"
	},
	{
		type: "p",
		content: [`El precio varía dependiendo de cada excursión 
		y normalmente ronda entre 70 y 100 TL`]
	},
	{
		type: "phtml",
		content: [`Si quieres saber las siguientes excursiones no dudes en contactar con ellos:`,		
		`<strong>Teléfono:</strong> 90 256 614 78 11<br/>
		<strong>Facebook:</strong> <a href="https://www.facebook.com/ekodosd">facebook.com/ekodosd</a><br/>
		<strong>Instagram:</strong> <a href="https://www.instagram.com/ekodosd">instagram.com/ekodosd</a>
		`,
		`También puedes visitarlos directamente en el centro, puedes encontrar más información en 
		<a href="/es/actividad/ekodosd-centro-de-naturaleza">
			Centro de Naturaleza en Kusdasi
		</a>`]
	},
	{
		type: "image",
		src: "kusadasi-beach.jpg",
		label: "Playa de Kusadasi"
	},	
]