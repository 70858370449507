let domainUrl;
let domainContent;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  domainUrl = 'http://localhost:3000/';
  domainContent = 'http://localhost:5009/';
} else {
  domainUrl = 'https://naturetravel.life/';
  domainContent = 'https://x.hectormarti.com/nt/';
}


export const DOMAIN_URL =	domainUrl;
export const DOMAIN_CONTENT =	domainContent;