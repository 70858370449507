import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import { Map as LeafLetMap, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import Hidden from '@material-ui/core/Hidden';

import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import ContactForm from '../../base/ContactForm';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { SRLWrapper } from 'simple-react-lightbox'


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { useTranslation } from 'react-i18next';



import ActivityPreview from '../../displays/Activity';

import {activities,getActivityIcon} from '../../../content/activities';

import {DOMAIN_CONTENT} from '../../../constants/constants';

const styles = ({ spacing,palette,shadows,breakpoints }: Theme) => ({
  	textTitle:{
   	marginTop: spacing(2),
   	color: palette.accentDark.color
  	},
  	subtitle:{
   	//color: palette.accentDark.color,
   	color: palette.secondary.dark,
   	marginBottom: spacing(4)
   },
  	mapContainer:{
   	height: spacing(60),
   	overflow: "hidden",
   	width: '100%',
  	},
  	list:{
   	margin: 0,
   	padding: 0,
   	listStyleType: "none",
        marginTop: spacing(4),
        "& li": {
          marginBottom: spacing(2),
        },
        "& b": {
          color: palette.accent.color,
        }
  	},
  	bodyContent:{
   	paddingTop: spacing(8),
      backgroundColor: palette.background.paper,
      fontSmoothing: "antialiased",
  	},
  	bodyTitle:{
   	marginTop: spacing(8),
   	marginBottom: spacing(8),
      [breakpoints.down('sm')]: {
         paddingLeft: spacing(4),
         paddingRight: spacing(4),
      },      
   	color: palette.accentDark.color,
  	},
  	bodyP:{
   	margin: spacing(4),
      "& a": {
         color: palette.accent.color,
      }
  	},
  	bodyGallery:{
  	},
  	credits:{
		backgroundColor: palette.primary.dark,  		
		color: palette.text.light,
		paddingTop: spacing(8),
		paddingBottom: spacing( 8 ),
		justifyContent: "center",
  	},
  	creditsRow:{
  		display: "flex",
  		alignItems: "center",
  		justifyContent: "space-evenly"
  	},
  	creditsRowBox:{
  		margin: spacing(1),
  	},
  	moreInfoRow:{
      backgroundColor: palette.background.paper,  		
		color: palette.text.light,
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
  	},
  	activitiesBox:{
  		backgroundColor: palette.primary.dark,
		paddingTop: spacing(8),
		paddingBottom: spacing( 8 ),
		justifyContent: "center",
	},
	typeBox:{
		display: "flex",
      justifyContent: "center",
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
	},
   titleBox:{
      display: "flex",  
      textAlign: "center",       
      flexDirection: "column",
      height: spacing( 60 ),
      position: "relative",
      justifyContent: "center",  
      filter: "blur(0px)", 
      boxShadow: shadows[3],
   },
   headerTitle:{
      margin: 0,
      padding:0,      
      letterSpacing: "3px",      
      textTransform: "uppercase",            
      color: palette.common.white,
      fontSmoothing: "antialiased",
      textShadow: "0px 1px 0px rgba(0,0,0,0.4),0px 18px 23px rgba(0,0,0,0.1);",
   },
   headerSubtitle:{
      fontStyle: "italic",
      marginTop: "8px",
      color: palette.common.white,
      textShadow: "0px 1px 0px rgba(0,0,0,0.4),0px 18px 23px rgba(0,0,0,0.1);",
   },
   iconBox: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      width: "100%",
      bottom: -spacing(6),
      padding: 0,
      margin: 0,
   },
   avatarType:{
      width: spacing(12),      
      height: spacing(12),      
      marginLeft: spacing( 4 ),
      marginRight: spacing( 4 ),
      backgroundColor: palette.secondary.dark,
      boxShadow: shadows[3],
   },
   titleBoxSm:{
      textAlign: "center",
      "& $headerTitle":{
         color: palette.secondary.dark,
         textShadow: "none"
      },
      "& $headerSubtitle":{
         color: palette.secondary.dark,
         textShadow: "none"
      }
   },
  
});

const Activity = (props) => {
  const { classes, activity} = props
  const { t,i18n } = useTranslation();
  
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41],
    iconAnchor: [25,41],
    popupAnchor: [-12,-25]
  });

  L.Marker.prototype.options.icon = DefaultIcon;


  let zoom = 13
  var sTileNational = { 
         url:'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
         attribution: 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
      };
    let sTileStreetMap = {
         url :'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }
  let sTile = sTileStreetMap
//  content/images/activities/iztuzu-beach
 
  let activityPath = DOMAIN_CONTENT +"images/activities/"
  const lng = i18n.language

  	function getImgSrc( src ){
   	return activityPath + activity.key +'/'+ src
  	}   

  	function getImg( name, title ){
  		return <img     
         src={getImgSrc( name ) +"?width="+ window.innerWidth}
         alt={title}
         title={title}
         style={{width: "100%"}} />
  	}


   const photoMaxWidth = window.innerWidth;

	const srlOptions = {
              		buttons: {
	              		showDownloadButton: false,
							showFullscreenButton: false,
							showThumbnailsButton: false
						},
					   thumbnails: {
					   	showThumbnails: true,
					   }
					} 
   const headerImage = activityPath + activity.key +"/"+ activity.cover +"?width="+ photoMaxWidth
  	/*
   <HeaderTitleBox
        parallax={false}
         image={activityPath + activity.key +"/"+ activity.cover +"?width="+ photoMaxWidth}
         title={activity.t[lng].title} subtitle={activity.t[lng].location +" - "+ activity.t[lng].country} />  
   */

   let creditsBlock = ""
   if( activity.t[lng].credits){
	   creditsBlock= <Grid container justify="center" className={classes.credits}>  
	   	<Grid item xs={10} className={classes.main} >
	      	<Typography component="h3" variant="h2" className={classes.subtitle} >{t("activities.credits")}</Typography>
	      	<div className={classes.creditsRow}>
	      		{activity.t[lng].credits.map( c => {
	      			if( c.type == "logo"){
	      				return <div className={classes.creditsRowBox}>
	      					<img src={c.url} />
	      					</div>
	      			}else if( c.type == "html"){
	      				return <div className={classes.creditsRowBox}>
	      					<Typography variant="caption">{c.content}</Typography>
	      				</div>
	      			}
	      		})}
	      	</div>
	    	</Grid>
	   </Grid>
  	}
  	return <div>	
   	<link rel="stylesheet" href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
      	integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
      	crossOrigin=""/>
    
      <Hidden smDown>
         <div className={classes.titleBox} style={{backgroundImage: "url("+ headerImage +")"}}>
            <Typography component="h1" variant="h1" className={classes.headerTitle} >{activity.t[lng].title}</Typography>
            <Typography component="h3" variant="h3" className={classes.headerSubtitle} >{activity.t[lng].location +" - "+ activity.t[lng].country}</Typography>
            <div justify="center" className={classes.iconBox}>
   					{activity.type.map( type => <Tooltip placement="bottom" key={"activity-"+ activity.key +"-"+ type} arrow title={t("activities.typeLabel."+ type )}>
   		      		<Avatar className={classes.avatarType}>{getActivityIcon( type )}</Avatar>
   					</Tooltip> )}
   	   	</div>
         </div>
      </Hidden>

    	
    	<Grid container justify="center" className={classes.bodyContent}>
         <Hidden smUp>
            <Grid item xs={10} >
               <div className={classes.titleBoxSm}>
                  <Typography component="h1" variant="h1" className={classes.headerTitle} >{activity.t[lng].title}</Typography>
                  <Typography component="h3" variant="h3" className={classes.headerSubtitle} >{activity.t[lng].location +" - "+ activity.t[lng].country}</Typography>            
               </div>
            </Grid>
         </Hidden>
	      <Grid item sm={8} >
	      	<SRLWrapper options={srlOptions} >
	        	{activity.t[lng].content.map((block,index) => {
		         switch( block.type ){
		            case "title":
		              	return <Typography  variant="h3" className={classes.bodyTitle} 
		               	key={"title-"+index} >{block.content}</Typography>
		              	break;
		            case "p":
		              	return block.content.map( (p,i2) => <Typography variant="body2"  className={classes.bodyP} 
		               	key={"p-"+index +"-"+i2}>{p}</Typography>)
		              	break;
		            case "html":
		              	return <Typography variant="body2" className={classes.bodyP} 
		              		key={"html-"+index}><span dangerouslySetInnerHTML={block.content} /></Typography>
		              	break;
		            case "phtml":
		              	return block.content.map( (p,i2) => <Typography variant="body2" className={classes.bodyP} key={"p-"+index +"-"+i2} ><span dangerouslySetInnerHTML={{__html:p}} /></Typography>)
		              	break;
		            case "image":
		            	return <a key={"img-"+index}
		            		href={getImgSrc(block.src)+"?width="+ photoMaxWidth} data-attribute="SRL">
		            			{getImg( block.src, block.title)}
		            		</a>
		              	break;
		            case "gallery":             
		               return <GridList key={"gallery-"+index} cellHeight={160} className={classes.bodyGallery} cols={block.cols || 3}>

			                  {block.gallery.map((tile,i2) => (

				                    	<GridListTile 
				                    		key={"gallery-"+ index + "-"+ i2} 
				                    		cols={tile.cols || 1}
				                    		rows={tile.rows || 1} >
				                    			<a href={getImgSrc(tile.src)+"?width="+ photoMaxWidth} data-attribute="SRL">
				                    				{getImg( tile.src, tile.title)}
				                    			</a>
				                    	</GridListTile>
			                  ))}
			               </GridList>
		            	break;
		           	default:
		           		return true
		           		break;
		          }
	        })}
	        </SRLWrapper>
	      </Grid>
   	</Grid>
    
   <Grid container justify="center">
      <Grid item sm={12} className={classes.mapContainer} >
        <LeafLetMap style={{height: '100%',width: '100%'}}
          center={activity.coordinates} 
          zoom={zoom}
          maxZoom={20}
          minZoom={2}
          scrollWheelZoom={false}
          touchZoom={false}
          dragging={true} >

          <TileLayer
            attribution={sTile.attribution}
            url={sTile.url}
            maxZoom={20}
          />
          
          <Marker position={activity.coordinates}>
            <Popup autoPan={false}>
              <b>{activity.addressTitle}</b><br/>
              {activity.address1}<br/>
              {activity.address2}
            </Popup>
          </Marker>
          
        </LeafLetMap>
      </Grid>
   </Grid>  

   {creditsBlock}    

   <Grid container justify="center" className={classes.moreInfoRow}>	
		<Grid item xs={10} className={classes.main} >
			<Typography component="h3" variant="h2" className={classes.subtitle} >{t("contact.moreInfo")}</Typography>
			<ContactForm />
		</Grid>
	</Grid>

	<Grid container justify="center" className={classes.activitiesBox}>
  		<Grid item xs={10}  >
  			<Typography component="h3" variant="h2" className={classes.subtitle} >{t("activities.otherActivities")}</Typography>
  			<Grid container spacing={4}> 
				{activities.map( a => {
					if( a.key ===  activity.key) return false
					return <Grid item xs={12} sm={4} key={"activity-preview-"+ a.key}>
						<ActivityPreview 
							short
							activity={a}
							location={a.location +", "+ a.country}/>
					</Grid>
					}
				)}
			</Grid>
		</Grid>
	</Grid>

</div>
}

export default withStyles(styles)(Activity);