import React from 'react';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing,breakpoints }: Theme) => ({
  	root:{
    	fontSmoothing: "antialiased",
    	color: "#FFF",  	
  		display: "flex",
  		borderBottom: "2px solid rgba(221,221,221)",
  		paddingBottom: spacing(1),
  		marginBottom: spacing(6)
  	},
  	title: {
		color: "#3f3f3f",
		display: "inline-block",
		margin: 0, 
		padding: 0,
		flexGrow: 2,
		flexBasis: 2,
		verticalAlign: "middle",
		textAlign: "left"
	},	
});

const TitleBox = (props) => {
  const {classes} = props

  return <div className={classes.root}>
  		<Typography component="h3" variant="h3" className={classes.title}>{props.title}</Typography>							
		</div>
}

export default withStyles(styles)(TitleBox)