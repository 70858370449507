import React, {Component} from 'react';



import Page1Col from '../base/Page1Col';
import Home from './home/Home';


class HomeContainer extends Component {

	render(){

		let main = <Home />
		return <Page1Col  main={main}  />
	}
}

export default HomeContainer