import React from 'react';
import ReactDOM from 'react-dom';

import WebFont from 'webfontloader';

import './i18n';

import './css/index.css';
import App from './components/App';


WebFont.load({
  google: {
    families: ['Lato:200,300,400,500,600,700,800,900','Noto Sans:300,700']
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
