import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import IconList from '../../displays/IconList';

import {Person,Explore,EmojiNature} from '@material-ui/icons/';

import PhotoColList from '../../displays/PhotoColList';

import { useTranslation } from 'react-i18next';

const styles = ({ palette, spacing }: Theme) => ({
	root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: palette.background.paper,
   },

   gridList: {
      width: "100%",
      transform: 'translateZ(0)', 
      marginTop: spacing( 0 ), 
      marginBottom: spacing( 0 ), 
   },
   gridItem:{
    	margin: 0,
    	padding: 0,
    	position: "relative",
    	overflow: "hidden",
    	height: spacing(60),
 		backgroundRepeat: "no-repeat",
  		backgroundSize: "cover",
  		backgroundPosition: "100%, 0",
   },
   gridImg:{
    	width: "100%"
    	  	
   },
	titleBarTop: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   titleBarBottom: {
      background: "none",
      paddingBottom: spacing(2),
   },
});

const About = (props) => {
	const { classes } = props
  const { t } = useTranslation();

  let aboutList = [
      {
        avatar: <Person />,
        background: "rgb(220,205,151)",
        title: t("about.meTitle"),
        description: t("about.meDescription",{ returnObjects: true})
      },
      {
        avatar: <EmojiNature />,
        background: "rgb(132,199,0)",
        title: t("about.whyTitle"),
        description: t("about.whyDescription",{ returnObjects: true})
      },
      {
        avatar: <Explore />,
        background: "rgb(0,175,178)",
        title: t("about.whatTitle"),
        description: t("about.whatDescription",{ returnObjects: true})
      }
  ]

  const iconList = <IconList list={aboutList} />

	return <div className={classes.root}>
	   <PhotoColList        
         image="https://naturetravel.life/content/images/general/hector-himalaya.jpg"
         content={iconList} />
  </div>
}

export default withStyles(styles)(About);