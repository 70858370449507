import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles'

import LogoText from '../../images/logo-text.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import {Phone,Email} from '@material-ui/icons/';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  	root: {
   	display: 'flex',
   	flexDirection: "column",
   	fontFamily: 'Lato',
  		fontSmoothing: "antialiased",
   },   	
   footer:{
  		color: "#bfbfbf",  
  		backgroundColor: palette.primary.main,
  		lineHeight: '18px',
  		padding: spacing(6),
  	},
   logoContainer:{
      textAlign: "center",
      [breakpoints.up('md')]: {
        textAlign: "left",
      },
    },
  	logo:{
  		width: "50%",
   	marginBottom: spacing(3),
   },
   title:{
  		fontSize: "12px",
  		marginBottom: spacing(2),
  		textTransform: "uppercase",	
  	},
  	contactList:{
  		margin: 0,
  		padding: 0,
  		listStyleType: "none",
  		"& li":{
  			marginTop: spacing(2),
  		}
  	},
  	contactIcon:{
  		verticalAlign: "bottom",
  		fontSize: "16px",
  		marginRight: spacing(2),
  	},
  	bottomBar: {
  		backgroundColor: palette.primary.dark,
  		//color: palette.primary.main,
  		color: palette.primary.contrastText
  	},
  	bottomBarContent: {
  		display: "flex",
  		justifyContent: "space-between",
  		padding: spacing(2),
  	},
  	socialLink: {
  		padding: spacing(1),
      marginRight: spacing(2),
  		color: palette.primary.contrastText
  	},



});

const Footer = (props) => {
  	const {classes} = props

  	return <div className={classes.root}>
  		<Grid container justify="center" className={cn(classes.footer)}>
	  		<Grid item sm={10}>
	  			<Grid container spacing={3}>
	  				<Grid item xs={12} sm={12} md={4} className={classes.logoContainer}>
		  				<img alt="Nature Travel" className={classes.logo} src={LogoText} />		  				
		  			</Grid>
		  			<Grid item xs={6} sm={6} md={3}>
              <Typography className={classes.title}>Contact Info</Typography>
		  				<Typography>Mugla, Turkey</Typography><br/>
		  				<ul className={classes.contactList}>
		  					<li>
		  						
                  		<Typography><Phone className={classes.contactIcon} /> +53 806 951 83</Typography>
		  					</li>
		  					<li>
		  						
                  		<Typography><Email className={classes.contactIcon} /> hector@naturetravel.life</Typography>
		  					</li>
		  				</ul>
		  			</Grid>
		  			<Grid item xs={6} sm={6} md={5}>
              		<Typography className={classes.title}>Partners</Typography>
		  			</Grid>
				</Grid>
	  		</Grid>
	  	</Grid>
	  	<Grid container justify="center" className={cn(classes.bottomBar)}>
	  		<Grid item sm={10} className={cn(classes.bottomBarContent)} >
	  			<div>
	  				<IconButton className={classes.socialLink}
	  					href="https://www.facebook.com/Nature-Travel-112481583817335" 
              			rel="noopener noreferrer" target="_blank" >
              			<Typography >
                  		<FontAwesomeIcon icon={faFacebookF} />
                 		</Typography>
              	</IconButton>
					<IconButton className={classes.socialLink} 
						href="https://www.instagram.com/naturetravel_life" 
              		rel="noopener noreferrer" target="_blank" >
             			<Typography >
             				<FontAwesomeIcon icon={faInstagram} />
             			</Typography>
             	</IconButton>
				</div>
	  			<div style={{paddingTop: "6px"}}>
					<Typography>Copyright 2020, Nature Travel, All Right Reserved</Typography>
				</div>
		  	</Grid>   
	  	</Grid>
  </div> 
}

export default withStyles(styles)(Footer)