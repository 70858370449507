export const iztuzuVolunteerEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`La Tortuga Boba es una de las pocas tortugas marinas que habitan en el mediterráneo.
		Ya nacen siendo unos grandes viajeros. Salen de sus huevos y tras una peligrosa carrera hacia el agua empiezan 
		a navegar por todo el mar. Las que son mas perezosas, se limitan a dar vueltas por las aguas de 
		los alrededores pero las muchas son grandes aventureras que viajan durante miles de kilómetros. 
		Eso sí una vez adultas, volverán cada año a la playa que las vio nacer, para poner sus huevos.`,
		`El nombre de tortuga boba es un poco ofensivo, pero hace alusión a que después de estar 
		debajo del agua durante mucho tiempo, pueden bucear por más de 10 horas, al salir a la superficie para
		respirar, algunas de ellas se quedan tan perezosamente amodorradas bajo el calor del sol que los pescadores 
		pueden agarrarlas con total facilidad, como el que recoge setas.`]
	},
	{
		type: "title",
		content: "Los peligros de las tortugas"
	},
	{
		type: "p",
		content: [`El impacto humano está añadiendo grandes peligros a la vida ya difícil de este incansable viajero.`,
		`Las bolsas de plástico por ejemplo las están dañando enormemente. La tortuga boba siempre ha disfrutado 
		de comerse un buen festín de medusas, tarea que la mayoría de bañistas deberíamos agradecer ya que reducen la 
		población de ellas, pero en lugar de esto lo que hacemos es llenar sus aguas de basura.
		Las pobres tortugas que no tienen la más mínima idea de lo que el plástico, confunden a las bolsas con medusas, 
		provocando muchas muertes por problemas intestinales y envenenamientos.`]	
	},
	{
		type: "image",
		src: "turtle-plastic.jpg",
		label: "Medusas y bolsas de plástico"
	},	
	{
		type: "p",
		content: [`Las redes de pesca es otro de sus grandes enemigos. Muchas tortugas se quedan 
		atrapadas en ellas mientras nadan tranquilamente y mueren al no poder subir a
		la superficie para respirar. `,
		`No solamente el fondo del mar está plagado de peligros. Los barcos y las lanchas motoras dañan muchísimas tortugas con sus hélices, 
		cuando salen a respirar o tomar el sol. Hay cientos de casos de tortugas con roturas en la concha, y terribles heridas en la cabeza. 
		La mayoría de veces estas heridas son mortales.`]
	},
	{
		type: "title",
		content: "El gran problema de los tours para ver tortugas en barco"
	},
	{
		type: "p",
		content: [`Al problema de las hélices se le ha de añadir los tours para ver las tortugas. Aunque puede parecer
		que no es perjudicial para ellas, esconden varios peligros.`,
		`La mayoría de barcos dan comida a las tortugas, para que los turistas
		pueden verlas y hacerles fotos. El mayor problema es que las tortugas asocian los barcos con comida así 
		que mientras nadan desde la relativa seguridad del fondo del mar, al ver la silueta de los barcos suben 
		hacia ellos. La mayoría de barcos no son de tours, que están esperando pacientemente a que las tortugas 
		lleguen, sino que lanchas motoras que engullen las engullen con con sus hélices.`,
		`Otro problema es que que muchos tours no solamente les dan cangrejo, que es uno de sus alimentos preferidos, sino que 
		les dan otras comidas, como pollo, mucho más barato. Esta alteración de sus hábitos alimenticios 
		les está causando muchas enfermedades.`]
	},
	{
		type: "image",
		src: "shell-hurt.jpeg",
		label: "Tortuga con la concha dañada"
	},
	{
		type: "title",
		content: "La destrucción de su entorno"
	},
	{
		type: "p",
		content: [`Además el ser humano está destruyendo todas las playas donde anidan. Los perros devoran los huevos, 
		los coches entran en la arena chafándolos y los bañistas con sus bosques de sombrillas los rompen.`,
		`Si algún huevo de los nidos llega a sobrevivir para que salga el bebé, el instinto los guía a ir hacia
		la luz de la luna reflejada en el agua, pero con las luces de las carreteras se confunden y van hacia ellas en su lugar,
		así que los pocos supervivientes son aplastados por los implacables coches.`
		]
	},
	{
		type: "image",
		src: "iztuzu-lagoon.jpeg",
		title: "Laguna de Iztuzu"
	},
	{	
		type: "p",
		content: [`Normalmente solo uno de cada 1000 huevos llega a ser una tortuga adulta, ahora con la influencia de los humanos
		es aún más difícil!!`,
		`Si quieres ayudar a las tortugas y aprender más sobre ellas, mientras vives durante un mes una playa paradisiaca este
		 voluntariado es lo que estás buscando!`]
	},
	{
		type: "title",
		content: "¿Dónde se hace?"
	},
	{
		type: "p",
		content: [`El voluntariado se hace en la playa de Iztuzu, 
		en Dalyan, a unos 20 km del pueblo. La playa se sitúa entre verdes montañas con 
		acantilados verticales precipitándose al mar, sin nada alrededor más que arena, mar y pinos.
		Un lugar único en el que cada año centenares de tortugas van a poner sus huevos.`,
		]
	},
	{
		type: "image",
		src: "iztuzu.jpeg",
		label: "Panorama de Iztuzu"
	},
	{
		type: "phtml",
		content: [`Este pequeño paraíso, hace 50 años estuvo a punto de desaparecer,
		 cuando quisieron construir hoteles y apartamentos en la playa. 
		 Por suerte ONG locales y internacionales lucharon para proteger el 
		 entorno y afortunadamente lo consiguieron. Si quieres saber más sobre lo que pasó,
		 lo explico en el post dedicado a 
		 <a href="/es/actividad/iztuzu-playa-de-las-tortugas">la playa de Iztuzu</a>.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "iztuzu-beach.jpeg",title: "Playa de Iztuzu",cols: 2,rows: 2},
			{src: "iztuzu-beach-3.jpeg",title: "Vegetación en Iztuzu",cols: 2,rows: 2},
		],
	},
	{
		type: "title",
		content: "¿Quién organiza el voluntariado?"
	},
	{ 
		type: "p",
		content: [`El voluntariado se hace en DEKAMER, un centro de investigación y protección 
		de las tortugas marinas que han estado trabajando incansablemente desde 2008 
		para salvar de la extinción a las tortugas.`,
		`El centro está ubicado en uno de los extremos de la playa,
		 y contiene un pequeño museo con un hospital para tortugas.`,
		 ]
	},
	{
		type: "image",
		src: "dekamer-museum.jpeg",
		title: "Museo DEKAMER"
	},
	{
		type: "p",
		content: [`Cada año centenares de tortugas son heridas por culpa de la ocupación humana. Los trabajadores
		de DEKAMER se encargan de curar a las tortugas hasta que puedan ser devueltas al mar. 
		 También informan y conciencian a los visitantes de Iztuzu sobre los peligros
		  a los que están sometidas las tortugas por culpa del hombre.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "turtle-hook.jpeg",title: "Tortuga en el hospital por comerse un anzuelo de pescar",cols: 2,rows: 2},
			{src: "turtle-shell-2.jpeg",title: "Tortuga en el hospital porque un barco le rompió la concha",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`Durante la época de cría, son también los responsables de la protección
		 de los nidos y sus huevos, para que el máximo número de bebes tortugas pueda llegar al mar.`]
	},
	{
		type: "image",
		src: "iztuzu-nest.jpeg",
		label: "Señales de nidos de tortugas"
	},
	{
		type: "title",
		content: "¿En que consiste el voluntariado?"
	},
	{
		type: "phtml",
		content: [`Los voluntarios tienen 4 tareas principales:<br/>
		<ul>
			<li><b>Cuidado y la alimentación de las tortugas.</b> Control de que el agua esté limpia y 
			a la temperatura adecuada así como renovación periódica el agua de los tanques.</li>

			<li><b>Información al visitante.</b> El centro dispone de un pequeño museo que acoge
			 cada año a miles de visitantes. Los voluntarios deben guiar a los visitantes 
			 a través de las instalaciones y del hospital, explicando las características 
			 de las tortugas así como los peligros que tienen.</li>
			
			<li><b>Patrulla la playa en época de cría.</b> Durante las noches de verano centenares de 
			tortugas van a Iztuzu a poner los huevos. Algunos voluntarios que se encargan de 
			patrullar la playa, rastreando las arenas en busca de nidos, para  marcarlos y protegerlos. </li>
			
			<li><b>Mantenimiento de las instalaciones.</b> Cuidado y limpieza general del centro, museo y hospital</li>
		</ul>`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "iztuzu-beach-2.jpeg",title: "Vistas de la playa de Iztuzu",cols: 2,rows: 2},
			{src: "dekamer-museum-2.jpeg",title: "Museo de las tortugas marinas en Iztuzu",cols: 2,rows: 2},
		],
	},
	{
		type: "title",
		content: "¿Cuál es el horario?"
	},
	{
		type: "phtml",
		content: [`<table>
			<tr>
				<th  style="min-width: 200px;">08:00</th>
				<td>Levantarse</td>
			</tr>
			<tr>
				<th>09:00</th>
				<td>Cuidado de las tortugas y limpieza de los tanques</td>
			</tr>
			<tr>
				<th>10:00</th>
				<td>Desayuno</td>
			</tr>
			<tr>
				<th>10:00 - 18:00</th>
				<td>Información al visitante y otras tareas</td>
			</tr>
			<tr>
				<th>18:00 - 20:00</th>
				<td>Tiempo libre y preparación de la cena</td>
			</tr>
			<tr>
				<th>20:00</th>
				<td>Cena</td>
			</tr>
			<tr>
				<th>22:00 - 10:00</th>
				<td>Patrulla nocturna en las playas
					<small style="font-size: 60%;">*No todos los días, ni todos los voluntarios</small></td>
			</tr>
			</table>`]
	},
	{
		type: "image",
		src: "turtle-info.jpeg",
		label: "Panel informativo para proteger los huevos de tortuga"
	},
	{
		type: "title",
		content: "¿Cuanto tiempo es la estancia mínima?"
	},
	{	
		type: "p",
		content: [`Los voluntarios han de estar como mínimo un mes, 
		y pueden alargarlo tanto como quieran.`]
	},
	{
		type: "title",
		content: "¿Cómo es el alojamiento y las comidas?"
	},
	{
		type: "p",
		content: [`El alojamiento se hace en contenedores con 4 literas. Los contenedores son separados 
		para hombres y mujeres. En verano también hay tienda de campaña afuera para los chicos.`, 
		`La organización proporciona un almuerzo copioso y cena. Durante el mediodía 
		hay una cocina por si quieres preparar algún tentempié.`]
	},
	{
		type: "title",
		content: "¿Cuánto cuesta?"
	},
	{
		type: "p",
		content: [`El precio es de 200€/mes que principalmente son 
		para cubrir los gastos de alojamiento y comida.`]
	},
	{
		type: "image",
		src: "iztuzu-view.jpeg",
		title: "¿No es una de las mejores playas de Turquía?"
	},
	{
		type: "title",
		content: "Otros detalles importantes"
	},
	{
		type: "p",
		content: [`La playa está 20 km del primer pueblo, Dalyan por lo que es 
		un lugar un poco aislado. Un día a la semana se va a la ciudad para hacer 
		compras, pero el resto del tiempo, a mínimo que haya algo 
		especial, se está en el centro.`]
	},	
]