import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import Page1Col from '../base/Page1Col';
import Activity from './activity/Activity';
import {activities} from '../../content/activities';


class ActivityContainer extends Component {
    
	render(){
		const {i18n} = this.props
		const lng = i18n.language
		let activityUrl = this.props.activityUrl
		let activity = activities.filter( f => (f.t && f.t[lng].url === activityUrl) )
		activity = activity[0]
		let main = <Activity activity={activity} />
		
		return <Page1Col  main={main}  />
	}
}

export default withTranslation()(ActivityContainer)