import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import {translationEn} from "./intl/en";
import {translationEs} from "./intl/es";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEn
  },
  es: {
    translation: translationEs
  }
};



i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'es',
    resources,
    order: ['querystring','path'],

    lookupFromPathIndex: 1,
    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackLng: ['es']


  });

  export default i18n;