import React from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles'


import { useTranslation } from 'react-i18next';

import ReCAPTCHA from "react-google-recaptcha";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';

import {DOMAIN_CONTENT} from '../../constants/constants';

const styles = ({ spacing  }: Theme) => ({
  	root: {
    	fontSmoothing: "antialiased",
    	color: "#000",
  	},
  	textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
   },
   button: {
    	marginTop: spacing(1),
     	marginBottom: spacing(1),
     	"& [h_loading=true]":{

     	}
   },
   alert: {
   	fontSize: "0.7em",
   }

});

const ContactForm = (props) => {
  	const {classes} = props
   const { t } = useTranslation();

  	const [name, setName] = React.useState();
  	const [nameHelper, setNameHelper] = React.useState();
  	const [email, setEmail] = React.useState();
  	const [emailHelper, setEmailHelper] = React.useState();
  	const [message, setMessage] = React.useState();
  	const [messageHelper, setMessageHelper] = React.useState();
   const [openSnack, setOpenSnack] = React.useState(false);
  	const [captcha, setCaptcha] = React.useState();
   const [refCaptcha, setRefCaptcha] = React.useState(false);
   const [submitLabel, setSubmitLabel] = React.useState(t("contact.sendForm"));


   const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    	setName(event.target.value);
  	};
  	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    	setEmail(event.target.value);
  	};
  	const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    	setMessage(event.target.value);
  	};  


   const handleCloseSnack = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }

      setOpenSnack(false);
   };

	const validateEmail = (email) => {
 		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
    		return true
  		}    	
    	return false
	}


  	const sendMessage = () => {  
  		setSubmitLabel(<CircularProgress color="secondary" />)   
  		
  		setNameHelper("")
  		setEmailHelper("")
  		setMessageHelper("")
  		let bCorrect = true
  		if( !name ){
  			setNameHelper(t("contact.error.emptyName"))
  			bCorrect = false
  		}
  		if( !email ){
  			setEmailHelper(t("contact.error.emptyEmail"))
  			bCorrect = false
  		}
  		if( !message ){
  			setMessageHelper(t("contact.error.emptyMessage"))
  			bCorrect = false
  		}

  		if( email && !validateEmail( email) ){
  			setEmailHelper(t("contact.error.incorrectEmail"))
  			bCorrect = false
  		}

      if( !captcha ){
         bCorrect = false
      }

  		if( bCorrect ){
         axios({
               method: "POST", 
               url: DOMAIN_CONTENT +"send-email", 
               data: {
                 name: name,
                 userEmail: email,
                 userMessage: message
               }
         }).then((response)=>{
            if (response.data.msg === 'success'){
               setOpenSnack(true);
               setName("")
               setEmail("")
               setMessage("") 
               
            }else{            
               alert("Oops, something went wrong. Try again")
            }
            setSubmitLabel(t("contact.sendForm"))
         })
  		}else{
  			setSubmitLabel(t("contact.sendForm"))
  		}

  		refCaptcha.reset()
           
  	}
 	function Alert(props: AlertProps) {
  		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}
 	const isError = (textHelper) => {
 		if( textHelper ){
 			return true
 		}
 		return false
 	}
  	return <div className={classes.root}>
		<form className={classes.root} onSubmit={() => sendMessage()} noValidate autoComplete="off">
			<Grid container justify="center" spacing={4}>
	  			<Grid item xs={12} sm={6}  >
		      		<TextField fullWidth required className={classes.textField}
		      			helperText={nameHelper} error={isError(nameHelper)}
		      			label={t("contact.name")} 
		      			value={name} onChange={handleChangeName}
		      			/>
		      	</Grid>
		      	<Grid item xs={12} sm={6}  >	
		      		<TextField fullWidth required className={classes.textField}
		      			helperText={emailHelper} error={isError(emailHelper)}
		      			label={t("contact.email")} 
		      			value={email} onChange={handleChangeEmail} 
		      			/>
		      	</Grid>
		      	<Grid item xs={12} sm={12} >
					     <TextField fullWidth required multiline className={classes.textField}
						    helperText={messageHelper} error={isError(messageHelper)}
						    label={t("contact.message")} 
						    value={message} onChange={handleChangeMessage}  />
		      	</Grid>
		      	<Grid item xs={12} sm={12} >
  		      	<ReCAPTCHA
  		      		hl="es"
          				ref={(el) => {setRefCaptcha(el)}}
          				sitekey="6Lfh6doZAAAAAOaqDyQMnbUZvkI-ckKCdLGWx5L4"
          				onChange={setCaptcha}
        				/>
            </Grid>
		      	
		      	<Grid item xs={12} sm={12} >
		      		<Button variant="contained" color="primary" onClick={() => sendMessage()}>
        					{submitLabel}
      				</Button>
		      	</Grid>
		    </Grid>
      		
      		
      		
      		
      	</form>
         <Snackbar
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
         	>
         	<Alert onClose={handleCloseSnack} severity="success" className={classes.alert}>
          		{t("contact.messageSendOk")}
        		</Alert>
        	</Snackbar>
	</div>
}

export default withStyles(styles)(ContactForm)