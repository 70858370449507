import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles'



const styles = ({ spacing,palette }: Theme) => ({
  	root: {
      fontSmoothing: "antialiased",
      justifyContent: "center",
      backgroundColor: palette.background.paper,

  	},    
    colText: {
      padding: spacing( 8 ),
    },  
    title: {
      color: palette.accentDark.color,
      marginBottom: spacing(8),      
      textAlign: "center"
    },  
    description: {
      textAlign: "center",
      color: palette.text.primary,
    },
});

const PhotoCol = (props) => {
  const {classes} = props

  return <Grid container className={cn(classes.root)} >
      <Grid item md={8} className={classes.colText}>
        <Typography component="h2" variant="h2" className={classes.title}>{props.title}</Typography>
        <Typography variant="body2" className={classes.description}>{props.text}</Typography>         
      </Grid>
   </Grid>
 
}

export default withStyles(styles)(PhotoCol)