import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';

import NatureButton from '../displays/NatureButton';

import {getActivityIcon} from '../../content/activities';

import {ExploreOutlined,CalendarTodayOutlined,ScheduleOutlined} from '@material-ui/icons/';


import Typography from '@material-ui/core/Typography';


import { useTranslation } from 'react-i18next';

import {DOMAIN_CONTENT} from '../../constants/constants';


const styles = ({ spacing,palette }: Theme) => ({
   root: {
      maxWidth: 345,
   },
   typeRow: {
      position: "absolute",

      bottom: spacing(-4),
      display: "flex",
      justifyContent: "center",
      width: "100%",
   },
   avatarType:{
      width: spacing(8),      
      height: spacing(8),      
      marginLeft: spacing( 1 ),
      marginRight: spacing( 1 ),
      backgroundColor: palette.secondary.dark,
   },
   media: {
      height: 180,
      position: "relative",
      marginBottom: spacing(4),
   },
   title:{
      textAlign: "center",
      color: palette.secondary.dark,
//      marginTop: spacing(1),    
      marginBottom: spacing(2),
   },
   description:{
      textAlign: "center",      
   },
   locationDate:{
      display: "flex",
      justifyContent: "space-between",
   },
   subtitle:{

   },
   infoIcon: {
      fontSize: spacing(5),
   },
   infoExtra: {
      borderTop: "1px solid #CCC",
      borderBottom: "1px solid #CCC",
      textAlign: "center",
   },
   infoExtraCol: {
      textAlign: "center",
   },
   button: {
      display: "inline-block",
      color: palette.accentDark.color,
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "1.3px"
   },
   actions: {
      display: "flex",
      justifyContent: "space-between",
   }
});

const Activity = (props) => {
  const {classes} = props
  const { t,i18n} = useTranslation();
  const lng = i18n.language
  let activity = props.activity
  
  if( !activity || !activity.t || !activity.t[lng]
   || !activity.t[lng].title ) return false

   const getLink = ( route ) => {
      if( i18n.language === "en"){
         return t("routes."+ route)
      }else{
         return "/"+ i18n.language +"/"+ t("routes."+ route)
      }
   }

  return <Card className={classes.root}>
        <CardMedia
            className={classes.media}
            image={DOMAIN_CONTENT +"images/activities/"+ activity.image +"?width=400"}
            title="Boat trip surveying seals"
         >
            <div className={classes.typeRow}>
               {activity.type.map( type => <Tooltip placement="top" key={"activity-"+ activity.key +"-"+ type} arrow title={t("activities.typeLabel."+ type )}>
                  <Avatar className={classes.avatarType}>{getActivityIcon( type )}</Avatar> 
               </Tooltip> )}
            </div>
         </CardMedia>
         <CardContent>
            <Typography gutterBottom variant="h4" component="h3" className={classes.title}>
               {activity.t[lng].title}
            </Typography>      
            <Typography variant="body1" color="textSecondary" component="div" className={classes.description}> 
               {activity.t[lng].short.map( (d,index) => <p 
                  key={"activity-"+ activity.key +"-p-"+ index}>{d}</p>)}
            </Typography>      
         </CardContent>

         <CardContent className={classes.infoExtra}>
            <Grid container justify="space-around">
               {activity.t[lng].dates? <Grid item xs={4} className={classes.infoExtraCol}>
                  <div>
                     <CalendarTodayOutlined className={classes.infoIcon} />
                  </div>
                  <Typography variant="caption">{activity.t[lng].dates}</Typography>
               </Grid>:""}
               {activity.t[lng].time? <Grid item xs={4} className={classes.infoExtraCol} >
                  <div>
                     <ScheduleOutlined className={classes.infoIcon} />
                  </div>
                  <Typography variant="caption" >{activity.t[lng].time}</Typography>
               </Grid>:""}
               <Grid item xs={4} className={classes.infoExtraCol} >
                  <div>
                     <ExploreOutlined className={classes.infoIcon} />
                  </div>
                  <Typography variant="caption" >{activity.t[lng].location}</Typography>
               </Grid>
            </Grid>
         </CardContent>
         <CardActions className={classes.actions}>
            <NatureButton external url={getLink("activity") +"/"+ activity.t[lng].url}  label={t("activities.preview.readMore")} />
            {(activity.contactLink && false)? 
              <NatureButton external url={activity.contactLink} label={t("activities.preview.contact")} />
            :""}         
          </CardActions>
      </Card>
  
    

}

export default withStyles(styles)(Activity)