import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import { useInView } from 'react-intersection-observer'
import { motion } from "framer-motion"

const styles = ({ spacing }: Theme) => ({

});

const Fade = (props) => {  
  	const [ref, inView] = useInView({
   		triggerOnce: false,
    	rootMargin: '-100px 0px',
  	})

          
  	return <div ref={ref}>
  		<motion.div  
	      //animate={{ y: inView ? 1: -1000 }}
	      initial={{
				opacity: 0
			}}
	      animate={{
				opacity: inView ? 1 : 0.3
			}}
	      transition={{ duration: 1.5 }} >
	         {props.children}			
    	</motion.div>
   </div>
}

export default withStyles(styles)(Fade)