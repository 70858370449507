import React from 'react';
import cn from 'classnames';

import { useInView } from 'react-intersection-observer'


import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden';

import SocialBar from '../base/SocialBar';
import NavTop from '../nav/NavTop';
import Footer from '../base/Footer';

const styles = ({ spacing,breakpoints,palette }: Theme) => ({
  	root: {
   	  display: 'flex',
      width: '100%',
      minHeight: '100vh'
  	},    
  	main: {
      width: '100%',
  	},
    triggerScroll:{
      position: "relative",
      zIndex: 1000,
    },
    content: {
    }

});

const Page1Col = (props) => {
  const {classes} = props

  const [refNavTop, isNavTopView] = useInView({
      triggerOnce: false,
      rootMargin: '0px 0px',
    })

  let navTopMin = true
  if( isNavTopView ){
    navTopMin = false
  } 
  return <div className={cn(classes.root)} >      
      <main className={cn(classes.main)}>
        <Hidden smDown>
          <SocialBar />
        </Hidden>
          
        <NavTop min={navTopMin} />
        <div ref={refNavTop} className={classes.triggerScroll}></div>
        
        <div className={classes.content} >
          {props.main}  
        </div>

        <Footer />

      </main>
  </div>    
}

export default withStyles(styles)(Page1Col)