import React from 'react';
import cn from 'classnames';
import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';
import Slide from '../animations/Slide';
import Fade from '../animations/Fade';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) => ({
  	root: {
  	},
   col: {
    	height: "100%"
   },
   box:{
      //backgroundColor: "rgba(0,0,0,0.8)",
      backgroundColor: "rgba(45,36,24,0.8)",
      fontSmoothing: "antialiased",
      borderRadius: "4px",
      color: "#FFF",
      height: "100%",
   },
  	col2:{
  		backgroundColor: "rgba(0,0,0,0.8)",
    	fontSmoothing: "antialiased",
    	borderRadius: "4px",
    	color: "#FFF",
  	}
});

const Info3Col = (props) => {
  	const {classes} = props

 	const box1 = <Box
               	className={classes.box}
               	boxShadow={3}>
                  	{props.content1}
            	</Box>

   const box2 = <Box
		         	className={classes.box}
		         	boxShadow={3}>
		           		{props.content2}
		         </Box>


  	return <Grid container justify="center"  className={cn(classes.root)} >
  			<Grid item sm={12} >
            <Grid container spacing={2} justify="space-around">
      			<Grid item md={5} className={classes.col}>
      				<Hidden smDown >
                		<Slide left >
								{box1}
                		</Slide>
                	</Hidden>
                	<Hidden mdUp>
                		<Fade>
								{box1}
                		</Fade>
                	</Hidden>
      			</Grid>
      			<Grid item md={5} className={classes.col}>
      				<Hidden smDown >
      					<Slide right style={{height:"100%"}}>
		              		{box2}
		            	</Slide>
		            </Hidden>
		            <Hidden mdUp>
                		<Fade>
								{box2}
                		</Fade>
                	</Hidden>
      			</Grid>
            </Grid>
      	</Grid>
      </Grid>
 
}

export default withStyles(styles)(Info3Col)