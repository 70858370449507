import React from 'react';

import NatureButton from '../displays/NatureButton';

import Typography from '@material-ui/core/Typography';


import { withStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';


const styles = ({ spacing,palette }: Theme) => ({
  	root: {
      fontSmoothing: "antialiased",
  	},

   aboutBox:{
      display: "flex",   
      marginBottom: spacing(4) 
   },
   avatar:{
      marginRight: spacing(8),
      height: spacing(8),
      width: spacing(8),
   },
   title: {
      color: palette.accentDark.color,
      textTransform: "uppercase",
      letterSpacing: "2px",
      marginTop: spacing(1),
      marginBottom: spacing(2),
   },
   description: {
      color: palette.text.primary,
      fontSize: "0.8rem",
   },
   buttonContainer:{
      textAlign: "right",
   },
});

const PhotoColList = (props) => {
  const {classes,list} = props

 
  return <div className={classes.colText}>
         {list.map((l,index) => {
            let action = ""
            if(l.action){
               action = <div className={classes.buttonContainer}>
                  <NatureButton contained dark external 
                     label={l.action.label} url={l.action.url} />
               </div>
            }
            let content = ""
            if( Array.isArray(l.description) ){
               content = l.description.map( (desc, index) => <Typography key={"photoColListDesc-"+ index} className={classes.description}>{desc}</Typography>)

            }else{
               content = l.description
            }
            return <div className={classes.aboutBox} key={"photocol"+ index }>
               <Hidden smDown>
                  <Avatar className={classes.avatar} style={{backgroundColor: l.background}}>
                     {l.avatar}
                  </Avatar>
               </Hidden>
               <div>
                  <Typography component="h5" variant="h5" className={classes.title}>{l.title}</Typography>
                  {content}
                  
                  {action}
               </div>
            </div>
            } )}
      </div>
 
}

export default withStyles(styles)(PhotoColList)