import React from 'react';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import Fade from '../animations/Fade';


const styles = ({ spacing,palette }: Theme) => ({
  	root:{
  		backgroundColor: "#f0f0f0",
  		textAlign: "left",
  		padding: spacing( 8 ),
    	fontSmoothing: "antialiased",
  	},
	highlight:{
		margin: spacing(3),
	},
	avatarBox:{
		display: "flex",
		justifyContent: "center",
	},
	avatar:{
		width: spacing(32),
		height: spacing(32),		
	},
	title: {
		marginTop: spacing(4),
		marginBottom: spacing(4),
		textAlign: "center",
		textTransform: "uppercase",
		letterSpacing: "1.3px",
		color: palette.accentDark.color,
	},
	description: {
		color: "#808080",
		textAlign: "center",
	}
});

	const PostList = (props) => {
  	const {classes} = props



  	return <Grid container justify="center" className={cn(classes.root)}>
  		<Grid item sm={10} className={cn(classes.main)} >
			<div>
				<Grid container spacing={6}>
					{props.list.map( (l) => {
						const description = (l.description)?<Typography className={classes.description}>{l.description}</Typography>:""
						return <Grid item md={4}>
								<Fade>
								<div className={classes.highlight}>
									<div className={classes.avatarBox}>
										<Avatar className={classes.avatar} src={l.avatar}></Avatar>
									</div>
									<Typography component="h5" variant="h5" className={classes.title}>{l.title}</Typography>
									{description}								
								</div>
								</Fade>
							</Grid> 
						}
					)}
				</Grid>
			</div>
  		</Grid>
  	</Grid>
}

export default withStyles(styles)(PostList)