import React, {Component} from 'react';


import Page1Col from '../base/Page1Col';
import Contact from './contact/Contact';


class ContactContainer extends Component {
    
	render(){

		let main = <Contact />
		return <Page1Col  main={main}  />
	}
}

export default ContactContainer