export const iztuzuVolunteerEn = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`Si estás en Kusadasi no pierdas la oportunidad de visitar \
		el centro de cultura y vida salvaje de EKODOSD. Està justo en el centro del pueblo,\
		 en una bonita casa tradicional turca, convertida ahora en museo.`]
	},
	{
		type: "image",
		src: "ekodosd-garden.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Recorre el jardin y las diferentes habitaciones de la casa,
		donde encontraras información de los diferentes animales y plantas que habitan Kusadasi, 
		así como algunas de las tradiciones de la región`]
	},
 	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "ekodosd-hall.jpeg",title: "Paseo Marítimo Izmir",cols: 2,rows: 2},
			{src: "ekodosd-room-animals.jpeg",title: "Paseo Marítimo Izmir",cols: 2,rows: 2},
			{src: "ekodosd-hector.jpeg"},
			{src: "ekodosd-room-traditional.jpeg"},			
			{src: "ekodosd-owl.jpeg"},
		],
	},
	{
		type: "p",
		content: [`Ekodosd también se dedican a cuidar animales salvajes 
		que han sufrido algún problema o lesión para despues volverlos a llevar a su habitat. 
		Con un poco de suerte quizas puedes llegar a conocer a alguno de estos huespedes ocasionales!`]
	},
	{
		type: "image",
		src: "kukumav.jpeg",
		label: "Carril Bici"
	},
	{
		type: "p",
		content: [`Sobretodo no olvides de preguntar cuales son las siguientes actividades que hay planeadas. 
		Cada dos semanas hacen excursiones por los alrededores.`]
	},
	{
		type: "title",
		content: "Horarios"
	},
	{
		type: "p",
		content: [`El centro normalmente abre de 9 de la mañana a 7 de la tarde 
		pero si los miembros tienen algun trabajo que hacer por los alrededores 
		es possible que esté cerrado.`,
		`Si quieres assegurarte de que està abierto puedes contactar con ellos con antelación.`]
	},
	{
		type: "title",
		content: "Contactos"
	},
	{
		type: "phtml",
		content: [`Para más informacón no dudes en contactar con ellos, 
		son muy amables y con gran interés en ayudar.`,		
		`<strong>Teléfono:</strong> 90 256 614 78 11<br/>
		<strong>Facebook:</strong> <a href="https://www.facebook.com/ekodosd">https://www.facebook.com/ekodosd</a><br/>
		<strong>Instagram:</strong> <a href="https://www.instagram.com/ekodosd">https://www.instagram.com/ekodosd</a>
		`]
	},
	{
		type: "image",
		src: "ekodosd-outside.jpeg",
		label: "Carril Bici"
	},
]