export const translationEs = {
	routes: {
		home: "/",
		about: "sobre-nosotros",
		animals: "animales",
    activity: "actividad",
    activities: "actividades",
    contact: "contactar",
	},
	menu: {
	  	"home": "Inicio",
	  	"about": "Nosotros",
	  	"animals": "Animales",
      "contact": "Contactar",
	},
	"general": {
		"learnMore": "Leer más",
		"readMore": "Leer más",
	},
	"home": {
		"carousel": {
         "izmir-bird-paradise":{
            "line1": "santuario para",
            "line2": "Flamencos",
            "line3": "Izmir, Turkey"
         },
         "iztuzu-beach":{
            "line1": "la playa de las",
            "line2": "Tortugas",
            "line3": "Dalyan, Turkey"
         },
         "kusadasi-walking-tours":{
            "line1": "paseos en la",
            "line2": "Naturaleza",
            "line3": "Kuadasi, Turkey"
         },
  			"seal":{
  				"line1": "Mediterranea",
  				"line2": "Foca Monje",
  				"line3": "Izmir, Turkey"
  			},
  			"caretta":{
  				"line1": "Caretta Caretta",
  				"line2": "Tortuga Marina",
  				"line3": "Dalyan, Turkey"
  			},
  			"ibis":{
  				"line1": "",
  				"line2": "Ibis Calvo",
  				"line3": "Sanliurfa, Turkey"
  			},
  		},
      block3Col:{
         col1:{
            title: "Por un turismo que respete la naturaleza.",
         },
         col2: {
            title: `Actividades para protejer animales en peligro de extinción.`
         },
         col3: {
            title: "Información sobre la vida salvaje a nuestro alrededor.",
         },
      },
      blockSustainable: {
         title: "Turismo sostenible",
         subtitle: "No cualquier turismo es bueno para el entorno",
         description: `Viajar está muy bien y es importante, pero el apelotonamiento masivo de gente en un pequeño
            lugar normalmente tiende a dañar el medioambiente. Queremos ayudar a proteger estas áreas, creemos que 
            la diversificación del turismo junto con alojamientos que protegen el entorno se puede llegar a ese punto
            en que el turismo es beneficioso para el planeta.`
      },
      blockAnimalProtection: {
         title: "Proteger a los animales",
         subtitle: "Muchos animales desaparecen justo a nuestro lado",
         description:  `Cada día más y más especies están siendo extinguidas. Sus hogares son comidos por ciudades, fábricas y granjas.
         Vamos a intentar darles voz, y con un poco de suerte buscar formas en que el turismo pueda ayudarlos, o como mínimo 
         ,como sucede en la mayoría de veces, evitar dañarlos.`          
      },
  		"about":{
  			"title": "Hola! Soy Héctor de Barcelona!",
  			"description": `Desde 2012 que tengo un estilo de vida nómada, viajando alrededor del mundo. Con esta web quiero 
        intentaré promocionar un turismo más minoritario y beneficioso con la naturaleza.`
  		}
  	},
   "activities": {
      "activities": "Actividades",
      "otherActivities": "Otras actividades",
      "preview": {
         "contact": "Contactar",
         "readMore": "Leer Más",
      },
      credits: "Agradecimientos",
      typeLabel: {
        scenary: "Paisaje",
        walk: "Ruta Caminando",    
        museum: "Museo",
        bicycle: "Ruta en Bicicleta",
        tour: "Tour",
        volunteer: "Voluntariado",
      }    
   },
   "contact": {
      contact: "Contactar",  
      moreInfo: "Más Información", 
      name:	"Nombre",
      email: "E-mail",
      message: "Mensaje",
      sendForm: "Enviar",
      error: {
      	emptyName: "El Nombre no puede estar vacío",
      	emptyEmail: "El Email no puede estar vacío",
      	incorrectEmail: "El Email no es correcto",
      	emptyMessage: "El Mensaje no puede estar vacío",
      },
      messageSendOk: "El mensaje se ha enviado correctamente",
      textContact: `Si tienes alguna duda o quieres comentarme alguna cosa puedes 
         escribirme un email desde aquí.`,
      textSocial: `También podemos chatear por facebook o instagram, que es más cómodo!`,
      buttonFacebook: `Facebook`,
      buttonInstagram: `Instagram`,
   },
   about: {
      meTitle: "Sobre Mi",
      meDescription: [
        `Hola! Soy Héctor, un programador de Barcelona.
        Desde 2012 llevo un estilo de vida nómada, viajando por el mundo sin rumbo fijo.`,
        `Mientras viajo, casi siempre estoy en ciudades desconocidas y sitios nuevos, aún así
        he conseguido construir una rutina que me permite trabajar alrededor del mundo 
        en casi cualquier lugar. Cafeterías, gasolineras, y hoteles, se convierten en oficinas 
        improvisadas para mi.`,
        `Mi trabajo me obliga a estar siempre enganchado al ordenador, así que prefiero llevarme
        el ordenador alrededor del mundo que quedarme siempre encerrado en una habitación!`
      ],
      whyTitle: "¿Por qué?",
      whyDescription: [
      `Desde que soy un niño que he sido un apasionado por la naturaleza.`,
      `Al haber nacido en una gran ciudad, las montañas 
      y los lagos quedaban muy lejos para mi pero me pasaba horas y horas delante guías de pájaros, 
      revistas de naturaleza o mirando documentales del mundo animal.`,
      `Creo que ha llegado el momento de empezar a explorar la naturaleza, 
      pero esta vez dejando de lado los libros y descubrir el mundo con mis propios ojos`      
      ],
      whatTitle: "¿Qué es Nature Travel?",
      whatDescription: [
         `Durante todos estos años de viaje, he visto lo frágil que es la naturaleza, 
         y como la estamos destruyendo a un ritmo frenético.`,
         `Con NatureTravel me gustaría poder hacer algo por los países que me hospedan, 
         promocionando un turismo que beneficie a la naturaleza y sus habitantes.`      
      ]
   },
}