import React, { useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import SizeContent from '../base/SizeContent';

import { ParallaxBanner } from 'react-scroll-parallax';

const styles = ({ spacing,breakpoints }: Theme) => ({
	root: {
	},
	content:{
		display: "flex",		
		flexDirection: "column",

		[breakpoints.up('md')]: {
			//height: "600px",
      	},
		zIndex: 2,
		position: "relative",
  		justifyContent: "center",
	},
	contentSm:{
		display: "flex",		
		flexDirection: "column",
		margin: spacing(6),
		zIndex: 2,
		position: "relative",
  		justifyContent: "center",
	},
	padding:{
		paddingTop: spacing(8),
		paddingBottom: spacing(8),
		paddingLeft: spacing(4),
		paddingRight: spacing(4),
	}
});

const ParallaxBox = (props) => {
	const { classes } = props
	

	const [height, setHeight] = useState(400);

	const onSize = size => {
		setHeight(size.height)
  	}

  	return <ParallaxBanner
	    			className={classes.root}
				    layers={[
				        {
				            image: props.background,
				            amount: -0.3,
				        },
				    ]}
				    style={{height: height}}>
				    <SizeContent onSize={onSize}><div className={classes.padding}>{props.children}</div></SizeContent>
			</ParallaxBanner>
}

export default withStyles(styles)(ParallaxBox);