import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';

import Deer from '../../images/icons/deer.png';
import Mountain from '../../images/icons/mountain.png';
import Tent from '../../images/icons/tent.png';

import { withStyles } from '@material-ui/core/styles'

import BoxIcon from '../displays/BoxIcon';
import Fade from '../animations/Fade';

const styles = ({ spacing,palette }: Theme) => ({
  	root: {
      	//backgroundColor: "#2d2418",
        backgroundColor: palette.primary.dark,

      	//borderTop: "5px solid #3f3221",
  	},
  	colCenter:{
		  //backgroundColor: "#3f3221",
      backgroundColor: palette.primary.main,      
  	},
});

const Info3Col = (props) => {
  const {classes} = props
  const { t } = useTranslation();
    
  return <Grid container justify="center" className={cn(classes.root)} >
  		<Grid item sm={10}>
  			<Grid container>
  				<Grid item md={4} >
            	<Fade>
               	<BoxIcon icon={Tent} 
                  	title={t("home.block3Col.col1.title")} />
            	</Fade>
  				</Grid>
          	<Grid item md={4} className={classes.colCenter}>
              	<Fade>
               	<BoxIcon icon={Deer} 
                  	title={t("home.block3Col.col2.title")}
                    text={[t("home.block3Col.col2.description")]} />
              	</Fade>             
            </Grid>
          	<Grid item md={4} >
              	<Fade>
               	<BoxIcon icon={Mountain} 
                  	title={t("home.block3Col.col3.title")}
                    text={[t("home.block3Col.col3.description")]} />
              	</Fade> 
         	</Grid>
         </Grid>
      </Grid>
   </Grid>
}

export default withStyles(styles)(Info3Col)