export const phrygianWayEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`Poca gente ha oído hablar de los Frigios, pero seguro que uno de sus reyes, 
		el Rey Midas, es conocido por todos, pues ya era famoso en tiempo de los griegos. 
		Su reino era tan rico y fascinante, con monumentales grabados en las paredes 
		de los acantilados, y templos dentro de las montañas que se labró la 
		leyenda de que todo lo que tocaba se convertía en oro. `]
	},
	{
		type: "image",
		src: "midas-king.jpeg",
		label: "Pintada del Rey Midas en Ayazini"
	},
	{
		type: "p",
		content: [`Los Frigios vivían en el centro de Turquía hace más de 
		2500 años, del siglo VIII antes de cristo, hasta el 500 antes de cristo, 
		cuando fueron absorbidos por los Lidios, y después por los medos (Persia). 
		Durante estos años crearon grandes ciudades, con impresionantes monumentos 
		y templos que han sobrevivido hasta nuestros días.`]
	},
	{
		type: "image",
		src: "phrygian-tomb.jpeg",
		label: "Tumba Frigia"
	},	
	{
		type: "title",
		content: "¿Qué es la ruta de los Frigios?"
	},
	{
		type: "p",
		content: [`Las carreteras que usaban los Frigios, han sido usadas durante tantos siglos, 
		que las ruedas de las carretas han esculpido salvajemente la roca, quedando marcadas en el suelo.`] 
	},
	{
		type: "image",
		src: "carro-children.jpeg",
		label: "Niños en carro tirado por caballos"
	},		
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "phrygian-road-1.jpeg",title: "Carretera Frigia",cols: 1,rows: 1},
			{src: "phrygian-road-2.jpeg",title: "Carretera Frigia",cols: 1,rows: 1},
			{src: "phrygian-road-3.jpeg",title: "Carretera Frigia",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`¡En algunos puntos hay trozos con más de 2 metros de profundidad! `]
	},
	{
		type: "image",
		src: "road-deep.jpeg",
		label: "Carretera Frigia con más de 2 metros de profundidad"
	},	
	{
		type: "p",
		content: [`La ruta de los frigios va buscando el rastro de sus carreteras recorriendo los valles frigios, 
		donde antiguas civilizaciones han dejado grandes huellas.`,
		`Monumentales tumbas, templos escarbados en las rocas, montañas convertidas en fortalezas,
		 y antiguas iglesias cristianas dentro de cuevas hacen del recorrido 
		 un impresionante museo al aire libre.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "byzantine-castle.jpeg",title: "Castillo bizantino",cols: 2,rows: 2},
			{src: "phrygian-cemetery.jpeg",title: "Tumba Frigia en cementerio",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`Todo esto atravesando bosques, campos y montañas de increíble belleza, 
		salpicados por pequeñas aldeas donde el tiempo no ha pasado.  
		`]	
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "phrygian-town-1.jpeg",title: "Pueblecito en la ruta",cols: 1,rows: 1},
			{src: "roads-1.jpeg",title: "Carretera entre el valle",cols: 1,rows: 1},
			{src: "phrygian-town-2.jpeg",title: "Casa al lado de monumento",cols: 1,rows: 1},
		],
	},
	{
		type: "title",
		content: "¿Cuántos kilómetros son?"
	},
	{
		type: "phtml",
		content: [`La ruta tiene 3 inicios diferentes y se unen todos en Yazilikaya.<br/> 
		<ul>
    		<li><b>Ruta 1</b> Seydiler - Yazilikaya : 140 km</li>
    		<li><b>Ruta 2</b> Yenice Çiftligi - Yazilikaya: 147 km</li>
    		<li><b>Ruta 3</b> Yazilikaya - Gordión : 219 km</li>
    	</ul>

Se puede hacer cualquiera de las rutas, 
unir dos de ellas o hacer las 3. Si se hacen las 3 hay 
que tener en cuenta que hay que dedicar un día para volver al centro (Yazilikaya) 
y continuar con la siguiente.`]	
	},
	{
		type: "title",
		content: "¿En bici o caminando?"
	},
	{
		type: "p",
		content: [`La ruta de los Frigios, está hecha principalmente para hacerse a pie, 
		pero incluye variantes para poder hacerse en bicicleta de montaña. `,
		`Técnicamente es fácil, sin grandes desniveles. Va recorriendo caminos, pistas sin asfaltar y esporádicamente carreteras sin tráfico.`]
	},
	{
		type: "image",
		src: "forest-way.jpeg",
		label: "Carretera de tierra entre los bosques"
	},
	{
		type: "p",
		content: [`Con bicicleta de carretera es un poco incómodo, sobre todo si se va muy cargado. Lo que recomendaría es seguir la ruta por la carretera, que son muy poco transitadas y van de ciudad en ciudad. De esta forma se pueden ver la mayoría de monumentos y lugares de interés, sin sufrir los baches y las piedras.`]	
	},
	{
		type: "image",
		src: "phrygian-temple-bicycle.jpeg",
		label: "Arslankaya monumento, cerca de Doger"
	},
	{
		type: "title",
		content: "¿Cuánto se tarda?"
	},
	{
		type: "phtml",
		content: [`La <b>ruta 1 (Desde Seydiler)</b> caminando se puede hacer en <b>una semana</b>, mientras que en bicicleta, con <b>2 días</b> es suficiente.<br/>
La <b>ruta 2 (Desde Çiftligi)</b> es igual que la anterior, mientras que la <b>ruta 3</b> es un poco más larga. Caminando son unos <b>10 días o 3 en bicicleta</b>. 
Si se hacen las 3 rutas hay que incluir un día extra para volver al centro.`]	
	},
	{
		type: "title",
		content: "¿Dónde dormir? ¿Qué hay de la comida y el agua?"
	},
	{
		type: "p",
		content: [`Aunque la mayoría de pueblos, disponen casas especiales para viajeros, es recomendable ir con tienda de campaña.`]
	}, 
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "frig-yolu-house.jpeg",title: "Casa de huéspedes",cols: 2,rows: 2},
			{src: "tent.jpeg",title: "Tienda de campaña",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`Durante la mayor parte del camino, el agua es muy abundante, ya que hay multitud de fuentes usadas por los pastores y para los animales.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "bebedero.jpeg",title: "Bebedero para animales",cols: 2,rows: 2},
			{src: "sheeps.jpeg",title: "Invasión de las ovejas a la carretera",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`El tema de la comida es un poco más difícil. No hay apenas restaurantes, y la mayoría de pueblos no tienen tiendas, o si las hay son muy básicas. En bicicleta la solución es fácil,  uno puede cargar la comida suficiente hasta llegar al final de la ruta.  Si se quiere hacer caminando hay que comprar la comida a la gente local en cada pueblo. Creo que es la opción más bonita, ya que permite conocer como es la gente de Turquía y disfrutar de su amabilidad y hospitalidad.`]	
	},
	{
		type: "image",
		src: "bicycle-landscape.jpeg",
		label: "Ir en bicicleta por estas carreteras es magnífico"
	},	
	{
		type: "title",
		content: "¿Cómo está marcada la ruta?"
	},
	{
		type: "p",
		content: [`La ruta está marcada con postes indicativos en los diferentes pueblos y mayoritariamente hay que seguir pintadas blancas y rojas, parecidas a los GR que se pueden encontrar en Europa, sin embargo no es fácil de seguirlas, ya que en algunos puntos se pueden haber borrado. `]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "signs-paintings.jpeg",title: "Uno puede seguir la ruta buscando las pintadas cuando el camino se divide",cols: 2,rows: 2},
			{src: "signs.jpeg",title: "Las señales indican la distancia que queda hasta el siguiente pueblo",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`Para mí es algo imprescindible conseguir la guía de la ruta de los Frigios. En ella se incluyen, mapas, los tracks de la ruta para instalar en tu GPS o en el móvil, y una descripción detallada  del camino a seguir.`]	
	},
	{
		type: "title",
		content: "¿Qué información hay en la guía? ¿Dónde puedo conseguirla?"
	},
	{
		type: "p",
		content: [`La guía aparte de un poco de historia e información sobre los Frigios,
		es un informe detallado de las diferentes etapas. Incluye el desnivel, los kilómetros, la dificultad,
		 así como los lugares donde se puede dormir o acampar, sitios donde encontrar agua, los pueblos con tiendas...`]
	},
	{
		type: "image",
		src: "guide.jpeg",
		label: "Guía de la ruta de los Frigios"
	},
	{
		type: "p",
		content: [`En cada etapa además indica si es se puede hacer en bicicleta, y en los casos en que el recorrido 
es demasiado pedregoso o abrupto para la bicicleta, recomienda rutas alternativas para poder llegar.
`,
`La ruta de los frigios es una zona muy rural, y la mayor parte de los lugares históricos y monumentos, hay que buscarlos. 
Muchas de las antiguas iglesias o cuevas están más frecuentadas por vacas y pastores que por turistas,
 por lo que si no se tiene la guía se pueden pasar de largo la mayoría de ellos.
`]
	},
	{
		type: "image",
		src: "cow-church.jpeg",
		label: "Las milenarias cuevas son frecuentadas más por vacas que por turistas"
	},
	{
		type: "p",
		content: [`Se puede comprar desde Amazon o en dos de los hoteles 
		que se encuentran a lo largo de la ruta. Midas Han, en Yazilikaya 
		(el centro de las rutas) o Frig Evi en Sabuncupinar,  
		al inicio de la ruta 2.`]	
	},
	{
		type: "title",
		content: "Ruta 1 - De Seydiler (Afyon) a Yazilikaya"
	},
	{
		type: "p",
		content: [`En mi opinión es la ruta más interesante de las 3 que existen. Si no hay tiempo de recorrerla todas ellas, es la más recomendable. 
Empezamos desde un pueblo cercano a Afyonkarahisar, Seydiler, y ya de buenas a primeras nos toca una con una buena subida a las montañas, 
atravesando bonitas zonas de bosque y pastos. `]
	},
	{
		type: "image",
		src: "seydiler-road.jpeg",
		label: "Entramos a los valles frigios"
	},
	{
		type: "p",
		content: [`Recorremos remotos valles, con cuevas de los primeros cristianos. Hasta nos cruzamos con magníficas iglesias excavadas en las rocas, ¡algunas de varios pisos de altura!`,
		`Todo esto nos recuerda un poco a la evolución de los templos que hacían los frigios a sus dioses, pero adaptado al cristianismo`]
	},
	{
		type: "image",
		src: "rock-church.jpeg",
		label: "Las iglesias esculpidas son una maravilla, herencia del estilo frigio de los templos en la roca"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "rock-cathedral.jpeg",title: "¡Esta catedral tiene 4 pisos de altura! Con escaleras dentro comunicando las diferentes salas",cols: 2,rows: 2},
			{src: "abraham-caves.jpeg",title: "Las cuevas de Abraham son espectaculares",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [ `Cruzamos Ayazini, un remoto pueblo donde las casas y las cuevas se usan por igual. Es un gran laberinto donde en cada esquina 
		se puede esconde recuerdo de siglos atrás.`]
	},
	{
		type: "image",
		src: "ayazini-houses.jpeg",
		label: "Ayazini es un laberinto de cuevas y casas"
	},	
	{
		type: "p",
		content: [ `Si hacemos el esfuerzo de subir una colina podemos 
		encontrarnos un espectacular castillo muy poco común.`]
	},
	{
		type: "image",
		src: "ayazini-fortress-back.jpeg",
		label: "Lo que parece ser una piedra caprichosa resulta ser un castillo"
	},
	{
		type: "p",
		content: [ `Lo que desde la lejanía parece ser una montaña de formas 
			extravagantes, al acercarnos vemos que está lleno de cuevas y escaleras.`]
	},
	{
		type: "image",
		src: "ayazini-fortress.jpeg",
		label: "Lo que parece ser una piedra caprichosa resulta ser un castillo"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "ayazini-fortress-windows-2.jpeg",title: "Mire por donde se mire el paisaje es espectacular",cols: 1,rows: 1},		
			{src: "ayazini-fortress-stairs.jpeg",title: "Las escaleras permiten subir al último piso de este castillo",cols: 1,rows: 1},
			{src: "ayazini-fortress-windows.jpeg",title: "Dentro está lleno de habitaciones, como una colmena",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [ `Nos encontramos ante lo que probablemente primero fuera un templo frigio, 
			y después fue usado como castillo por el imperio bizantino. 
			Una increíble fortaleza de piedra con una vista privilegiada de toda la región.`
		]
	},	
	{
		type: "image",
		src: "ayazini-fortress-views.jpeg",
		label: "Las vistas desde arriba no tienen precio"
	},	
	{
		type: "p",
		content: [ `Vamos siguiendo por valles donde parece haberse parado el tiempo.`,
		`Dejamos las montañas y llegamos a la llanura, donde la naturaleza por un lado y
		 los frigios por otro la han convertido en un lugar mágico.`]
	},
	{
		type: "image",
		src: "lion-rock.jpeg",
		label: "La roca de los leones nos da la bienvenida"
	},
	{
		type: "p",
		content: [ `Dos grandes leones excavados en una pared de piedra nos dan bienvenida nada más llegar, 
		mientras que una gigante cabeza de león,
		 destruida por el tiempo reposa al lado de la carretera.`]
	},
	{
		type: "image",
		src: "lion-head.jpeg",
		label: "¿Puedes ver la cabeza de león? Es inmensa!"
	},
	{
		type: "p",
		content: [ `Esta zona era un importante centro Frigio y abundan sus obras.`,
		`Volvemos a encontrarnos con varios castillos excavados dentro las rocas 
		y finalmente vemos las carreteras Frigias en su máximo esplendor. `
		]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "doger-road-3.jpeg",title: "Las famosas carreteras frigias",cols: 2,rows: 2},
			{src: "doger-road-2.jpeg",title: "Se pueden ver las diferentes alternativas que iban tomando los carros",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [ `¡Han sido usadas ininterrumpidamente durante milenios y las ruedas de los carros han ido marcando el terreno, 
		dejando unas grandes hendiduras,
		 algunas de ellas con más de 3 metros de profundidad! `]
	},
	{
		type: "image",
		src: "doger-road-1.jpeg",
		label: "Es realmente increíble como durante milenios, las ruedas han dado forma a las piedras"
	},
	{
		type: "p",
		content: [ `Alrededor de las carreteras podemos ver altares y templos, algunos en bastante mal estado, 
		pero los que han sobrevivido al día de hoy te dejan la boca abierta. `
		]
	},
	{
		type: "image",
		src: "arslankaya-monument.jpeg",
		label: "Este templo es inmenso! Pero no se aprecia en la foto"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "burmese-monument.jpeg",title: "Los restos del Burmese Monument",cols: 1,rows: 1},
			{src: "demirli-castle-1.jpeg",title: "El castillo de Demirli, es un rincón mágico alejado de todo",cols: 1,rows: 1},
			{src: "demirli-castle-2.jpeg",title: "¿Recuerda un poco a una calavera, verdad?",cols: 1,rows: 1},
			{src: "altar-temple.jpeg",title: "Restos de altares y templos abundan por la región",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [ `El clima también ha moldeado los valles, creando infinidad de chimeneas de hada y 
			grandes formaciones rocosas de formas imposibles, 
			que le dan a esta parte del recorrido un toque irreal.`],
	},
	{
		type: "image",
		src: "fairy-1.jpeg",
		label: "Las chimeneas de hada hacen que el lugar parezca mágico"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "fairy-2.jpeg",title: "Las carreteras frigias también atraviesan estas montañas",cols: 1,rows: 1},
			{src: "fairy-4.jpeg",title: "Todo el lugar parece fantástico",cols: 1,rows: 1},
			{src: "fairy-3.jpeg",title: "¡Las chimeneas de hada son gigantescas!",cols: 1,rows: 1},
			{src: "fairy-5.jpeg",title: "Mire donde se miden se pueden ver grandes chimeneas",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [ `Volvemos a cruzar la montaña a través de un solitario bosque. 
		Ahora tenemos una dura subida, donde arriba del todo, 
		nos espera la capital religiosa de los frigios, Yazilikaya, 
		la ciudad del rey Midas.`]
	},
	{
		type: "image",
		src: "yazilikaya-midas-wall.jpeg",
		label: "La pared esculpida es gigantesca!"
	},
	{
		type: "p",
		content: [`Lo primero que vemos es el magnífico templo, esculpido en la pared del barranco, en el que se puede ver la inscripción del rey Midas.`,
		`Enfrente hay una gran roca llena de cuevas como un queso Gruyere. En tiempos de los frigios, fue un templo,
		 castillo durante la época bizantina y viviendas hasta hace pocos años.`]
	},
	{
		type: "image",
		src: "yazilikaya-temple.jpeg",
		label: "El antiguo templo es un lugar surealista"
	},
	{
		type: "p",
		content: [`Los alrededores de Yazilikaya están sembrados de castillos, y restos de templos, que dan para varios días de investigación.`]	
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "yazilikaya-midas.jpeg",title: "Encima de la pared esculpida, puede leerse el nombre del rey Midas",cols: 1,rows: 1},
			{src: "yazilikaya-minor-temple.jpeg",title: "El segundo monumento, es más pequeño pero sigue siendo impresionante",cols: 1,rows: 1},
			{src: "yazilikaya-temple-far.jpeg",title: "El templo, fue castillo durante el imperio bizantino y hasta hace pocos años ocupado por gente",cols: 1,rows: 1},
			{src: "yazilikaya-path.jpeg",title: "Todo el lugar rebosa de magia",cols: 1,rows: 1},
		],
	},
	{
		type: "title",
		content: "Ruta 2 - De Yenice Çigtligi (Kuthaya) a Yazilikaya"
	},
	{
		type: "p",
		content: [`A unos 20 km de Kuthaya empieza la segunda ruta. 
		De duración similar a la ruta 1, menos intensa a nivel arqueológico, 
		pero recorre zonas donde la naturaleza es más salvaje, entre bosques 
		y valles de increíble belleza`]
	},
	{
		type: "image",
		src: "ruta2-cueva.jpeg",
		label: "Hay multitud de cuevas, que eran casas y templos de la antigüedad"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "ruta2-road-2.jpeg",title: "Carreteras solitarias son un placer para la bicicleta",cols: 2,rows: 2},
			{src: "ruta2-lago.jpeg",title: "Embalse en la ruta",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`
		De vez en cuando van apareciendo minúsculos pueblos, 
		donde el tiempo se detuvo hace muchos siglos. `]
	},
	{
		type: "image",
		src: "ruta2-road.jpeg",
		label: "La ruta esta salteada por multitud de pueblos minúsculos"
	},	
	{
		type: "p",
		content: [`La historia también ha dejado marca en estos valles, 
			con multitud de cuevas en las paredes,
			templos frigios esculpidos en las rocas, 
			tumbas otomanas...`]
	},
	{
		type: "image",
		src: "khumbet.jpeg",
		label: "Khumbet, está en una colina y recibe el nombre de la tumba otomana que hay en la cima"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "khumbet-fields.jpeg",title: "Campos alrededor de Khumbet",cols: 1,rows: 1},
			{src: "khumbet-detail.jpeg",title: "Detalle de la tumba otomana",cols: 1,rows: 1},
			{src: "khumbet-lions.jpeg",title: "Justo en la cima encontramos una increíble tumba frigia",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`Al igual que la ruta primera, 
		termina en la capital religiosa de los frigios, Yazilikaya.`]	
	},
	{
		type: "image",
		src: "yazilika-moon.jpeg",
		label: "Yazilikaya, la ciudad del rey Midas"
	},
	{
		type: "title",
		content: "Ruta 3 - De Yazilikaya a Gordión (Ankara)"
	},
	{
		type: "p",
		content: [`Es la ruta más larga de las tres, y une la capital religiosa de los frigios, con su capital política Gordión.`,
		`Salimos de Yazilikaya y bajamos la montaña, llena de prados y árboles.`]
	},
	{
		type: "image",
		src: "byzantine-stones.jpeg",
		label: "Entre los prados pueden verse restos de antiguas iglésias"
	},
	{
		type: "p",
		content: [`De golpe se es sorprendido con un cambio de escenario radical ya que pasamos a una increíble llanura de campos, 
		que se alarga en todas direcciones 
		hasta donde alcanza la vista.`]
	},
	{
		type: "image",
		src: "field-desert.jpeg",
		label: "En verano parece un desierto"
	},
	{
		type: "p",
		content: [`Vamos buscando el camino entre el laberinto de campos. 
		Los colores son tan hermosos y la visión tan extensa, que da la impresión de estar paseando 
		por dentro de un cuadro. En cualquier dirección donde se mire hay un paisaje de postal.
		`]
	},
	{
		type: "image",
		src: "desert-fields-1.jpeg",
		label: "¿Cuál es el camino correcto?"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "desert-fields-3.jpeg",title: "Camino entre campos de cultivo",cols: 1,rows: 1},
			{src: "desert-fields-2.jpeg",title: "La inmensidad del terreno lo hace muy especial",cols: 1,rows: 1},
			{src: "desert-fields-4.jpeg",title: "Parece que sea un cuadro",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`Vamos cruzando pueblos que sin lugar a dudas, han vivido mejores momentos. 
		Muchos parecen abandonados, como si toda la población hubiera marchado hace años y hubieran quedando
		los cascarones de las casas vacías desperdigadas entre el polvo. 
		Es como meterse en el escenario de una película del oeste.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "old-house-2.jpeg",title: "Muchas de las casas están destrozadas",cols: 2,rows: 2},
			{src: "old-house-1.jpeg",title: "En los pueblos no hay mucho ambientillo",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`En la inmensidad de la llanura poco a poco van apareciendo colinas castaño claro,
		 que parecen pintadas a pincel.`]
	},
	{
		type: "image",
		src: "fields-mountain.jpeg",
		label: "¿No parece que está pintado con pincel?"
	},
	{
		type: "p",
		content: [`Al llegar al río Sakarya volvemos a tener otra sorpresa con el paisaje. 
		Nos metemos dentro de un estrecho valle, con el río, que se intuye
		tras la sinuosa linea verde de los arboles que crecen a su alrededor.`]
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "sakarya-valley.jpeg",title: "La entrada al valle del río Sakarya",cols: 2,rows: 2},		
			{src: "sakarya-river.jpeg",title: "El río Sakarya se esconde dentras de la línea de árboles",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`El valle del río Sakarya es otro paisaje de postal, 
		la pequeña carretera serpentea en medio de valle al lado de prados, con las montañas justo en frente.`,
		`En mitad del valle, aparece como si fuera un espejismo , una urbanización en construcción. Parece ser que están
		construyendo una zona de hoteles y apartamentos, lo que probablemente va a significar la muerte del valle. 
		Imagino que es cuestión de años, para que lleguen las grandes carreteras al lugar y desaparezca el idílico lugar.
		`]
	},
	{
		type: "image",
		src: "sakarya-valley-4.jpeg",
		label: "El valle del Sakarya, un lugar mágico"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "sakarya-valley-2.jpeg",title: "Prados con las montañas de fondo",cols: 1,rows: 1},
			{src: "sakarya-valley-1.jpeg",title: "La carretera del valle del Sakarya",cols: 1,rows: 1},
			{src: "sakarya-valley-3.jpeg",title: "Valle de Sakarya ",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`El valle cada vez se va abriendo más hasta que llegamos a la reserva de aves de Balikdami.
		Es un pequeño oasis de aguas transparentes, en el que se pueden ver flamencos y multitud de aves acuáticas. `]
	},
	{
		type: "image",
		src: "balikdami-pantano.jpeg",
		label: "Las transparentes aguas de Balikdami"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "balikdami-paisaje.jpeg",title: "Prados con las montañas de fondo",cols: 1,rows: 1},
			{src: "balikdami-rio.jpeg",title: "La carretera del valle del Sakarya",cols: 1,rows: 1},
			{src: "balikdami-flamencos.jpeg",title: "Valle de Sakarya ",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`En este punto con un poco de pena nos alejamos del río Sakarya 
		y empezamos a subir progresivamente el valle, por rectas infinitas que transportan a uno
		 a los remotos desiertos de los estados unidos. `]
	},	
	{
		type: "image",
		src: "sakarya-road-1.jpeg",
		label: "Las transparentes aguas de Balikdami"
	},
	{
		type: "gallery",
		cols: 4,
		gallery: [
			{src: "sakarya-road-2.jpeg",title: "Prados con las montañas de fondo",cols: 2,rows: 2},
			{src: "sakarya-road-3.jpeg",title: "Valle de Sakarya ",cols: 2,rows: 2},
		],
	},
	{
		type: "p",
		content: [`Llegamos a Ballihisar, polvoriento pueblo con las ruinas de Pessinus, 
		una antigua ciudad romana aún en excavación 
		y continuamos por la fuerte subida del valle`]
	},
	{
		type: "image",
		src: "pessinus.jpeg",
		label: "Pessinus, la antigua ciudad romana"
	},
	{
		type: "p",
		content: [`Durante unos kilómetros, dejamos la calma de la naturaleza, 
		para acompañar por un caminito, a la autopista que atraviesa la región, 
		bien cargada de camiones y coches que van hacia la capital.`,
		`Nos desviamos, de nuevo alejándonos de la autopista y nos reencontramos el río Sakarya, 
		que tristemente parece llevar menos agua que en sus inicios. 
		A su lado, en medio de la nada, oculto tras un pequeño valle, 
		aparecen los restos de Gordión, la capital del mítico reino que tantas leyendas ha creado. `
		]
	},
	{
		type: "image",
		src: "gordion.jpeg",
		label: "Gordión, la capital de Frigia"
	},
	{
		type: "p",
		content: [`¡Aún no hemos terminado! Al admirar la ciudad, 
		se pueden ver multitud de colinas de formas redondeadas. 
		Son antiguas tumbas cubiertas de tierra para protegerlas de los saqueadores.  
		`]	
	},	
	{
		type: "image",
		src: "pyramid-1.jpeg",
		label: "Pirámide de Gordio"
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "pyramid-2.jpeg",title: "Entrada a la pirámide, la tumba de Gordio",cols: 1,rows: 1},
			{src: "pyramid-3.jpeg",title: "Interior de la pirámide",cols: 1,rows: 1},
			{src: "pyramid-4.jpeg",title: "Tumba de Gordio, rey de Frigia. Es la construcción de madera más antigua del mundo",cols: 1,rows: 1},
		],
	},
	{
		type: "p",
		content: [`La más grande de ellas es la tumba del padre de Midas, Gordio, su rey más importante.
		 Al lado hay el museo arqueológico, por donde se puede entrar
		 a la gigantesca tumba y ver la estructura de madera más antigua del mundo 
		 con más de 3000 años de antigüedad.`,
		 `Ahora ya se puede dar por finalizado este viaje al pasado que seguro 
		 que no le habrá dejado indiferente.`]	
	},	
	{
		type: "title",
		content: "Obtener más información"
	},
	{
		type: "phtml",
		content: [`Si tienes cualquier duda, puedes contactarme desde esta web, 
		desde el formulario, que hay debajo, desde 
		<a href="https://www.facebook.com/naturetravel10/" target="_blank">Facebook</a> o <a href="https://www.instagram.com/naturetravel_life/" target="_blank">Instagram</a>.
		Será un placer para mí ayudarte en todo lo que pueda y de paso recordar mis aventuras en 
		Frigia.`,
		`También puedes contactar con la organización encargada de la ruta a través de su web:<br/>
		<a href="http://www.frigyolu.com/eindex.htm" target="_blank">www.frigyolu.com</a><br/>`]
	},
]