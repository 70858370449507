import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import { useInView } from 'react-intersection-observer'
import { motion } from "framer-motion"

const styles = ({ spacing }: Theme) => ({

});

const Slide = (props) => {
  
  	const [ref, inView] = useInView({
    	triggerOnce: true,
    	rootMargin: '-100px 0px',
  	})

  	let effects = {
  		initial: {},
  		animate: {},
  	}

  	if( props.right ){
  		effects.initial.x = 2000
  		effects.animate.x = 0

  	}else{
		  effects.initial.x = -2000
  		effects.animate.x = 0		
  	}

  	return <div ref={ref}>
      <motion.div 
    		style={{height: "100%"}}
        	initial={effects.initial}
        	animate={{
  				x: inView ? effects.animate.x : effects.initial.x
  			}}
        	transition={{ duration: 1.5 }} >
           {props.children}			
      </motion.div>  	
   </div>			
}

export default withStyles(styles)(Slide)