import React from 'react';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import { Link } from "@reach/router";

import { withStyles } from '@material-ui/core/styles'



const styles = ({ spacing,palette,breakpoints }: Theme) => ({
	root:{
		textDecoration: "none",
	},
	button: {
      display: "inline-block",
      color: palette.accentDark.color,
   },
   buttonContained:{
     	color: "#FFF",
      margin: spacing(2),
      marginBottom: spacing(4),
   },
   light:{
   	backgroundColor: palette.accent.color,
   },
   dark:{
   	backgroundColor: palette.accentDark.color,
   }
   ,buttonBig:{
      color: "#685617",
      backgroundColor: "#ecb338",
      padding: spacing(2),
      margin: 0,
      fontSize: "10px",
      [breakpoints.up('md')]: {
        padding: spacing(4),
        fontSize: "12px",
      },
   }
});

const NatureButton = (props) => {
  	const {classes} = props
 
	let buttonClass = []
	let buttonAttr = {}

 	let button = ""
 	if( props.external ){
   	buttonAttr["href"] =  props.url
 	}

 	if( props.contained ){
   	buttonAttr["variant"] =  "contained"

		buttonClass.push(classes.buttonContained )
	
		if( props.dark ){
			buttonClass.push(classes.dark)
		}else{
			buttonClass.push(classes.light) 		
		}
		
		if( props.big ){
			buttonClass.push(classes.buttonBig)
		}
	}else{
		buttonClass.push(classes.button )
	}
		
   button = <Button className={cn(buttonClass)} { ...buttonAttr } >
			{props.label}
		</Button>				
		
 	
 	if( props.external ){
 		return <div className={classes.root}>
 				{button}
 			</div>

 	}else{
 		return <Link to={props.url} className={classes.root}>   
 				{button}
 			</Link>
 	}
}

export default withStyles(styles)(NatureButton)