import React, {Component} from 'react';


import Page1Col from '../base/Page1Col';
import Animal from './animal/Animal';


class AnimalContainer extends Component {
    
	render(){

		let main = <Animal />
		return <Page1Col  main={main}  />
	}
}

export default AnimalContainer