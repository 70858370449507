export const iztuzuEs = [
	/*{
		type: "title",
		content: "Iztuzu, la playa de las tortugas"
	},*/
	{
		type: "p",
		content: [`El sur oeste de Turquía acoge de una de las playas más bonitas que he visto, Iztuzu. 
		Rodeada de acantilados rebosantes de pinos, la arenosa playa se estira entre el mar y la laguna. 
		Este pequeño paraíso de naturaleza es también la casa de la tortuga boba, 
		donde cada año cientos de ellas van a sus orillas para poner sus huevos.`]
	},
	{
		type: "image",
		src: "iztuzu-about.jpeg",
		label: "Vistas de la playa"
	},
	{
		type: "p",
		content: [`No hay apenas construcciones, solo unos pequeños restaurantes, algunas zonas de tumbonas 
		y sombrillas para los bañistas repartidas por la playa, y 4 km de fina arena que hacen de la playa 
		de Iztuzu el perfecto ejemplo de como encontrar el equilibrio entre el turismo y la protección de la naturaleza.`]
	},
	{
		type: "image",
		src: "iztuzu-sunbeds.jpeg",
		label: "Tumbonas en Iztuzu Beach"
	},
	{
		type: "p",
		content: [`Sus horarios son restringidos, solo se puede acceder a la playa de 
		8 de la mañana a 8 de la tarde, ya que por la noche, cuando se van los turistas 
		vienen los más importantes huéspedes, las tortugas. A principios de verano, centenares de ellas 
		llegan a las arenas de Iztuzu para poner sus huevos.`]
	},
	{
		type: "p",
		content: [`Pasear por el magnífico entorno de Iztuzu, es una experiencia única. 
		Mientras se anda por la arena frecuentemente se puede encontrar el rastro de alguna 
		tortuga que ha salido del agua para hacer el nido, o las cascaras de huevos rotos.
		Tras la deliciosa caminada uno puede recuperarse bebiendo un te o comiendo un poco 
		en los restaurantes que hay a ambos extremos de la playa, mientras se va dando un chapuzón 
		de vez en cuando para refrescarse del calor`]
	},
	{
		type: "gallery",
		cols: 3,
		gallery: [
			{src: "iztuzu-walk-1.jpeg",title: "Magnifica playa de Iztuzu"},
			{src: "iztuzu-walk-2.jpeg",title: "Vistas de la laguna pequeña de Iztuzu"},
			{src: "iztuzu-walk-3.jpeg",title: "Cáscaras de huevos de tortuga"},
		],
	},
	{
		type: "title",
		content: "Centro de Información y Hospital de tortugas marinas"
	},
	{
		type: "phtml",
		content: [`Una visita a Iztuzu no está completa sin antes aprender un poco sobre las tortugas en DEKAMER, 
		el centro de estudio y protección de las tortugas que hay al inicio de la playa.
		En el centro tienen un pequeño museo donde los trabajadores te explican la situación de las tortugas, 
		y sus peligros. Después se puede visitar el hospital y conocer las tristes historias de sus pacientes. 
		La mayoría de tortugas han sido heridas por culpa de los humanos, especialmente las lanchas a motor 
		o las herramientas de pesca.`,
		`Si quieres ayudar a las tortugas, el centro permite trabajar de voluntario en él. Puedes encontrar más Información
		en la página dedicada al <a href="/es/actividad/iztuzu-tortuga-marina-voluntariado">Voluntariado con tortugas marinas</a>`
		]
	},
	{
		type: "gallery",
		gallery: [
			{src: "iztuzu-museum.jpeg",title: "Museo DEKAMER"},
			{src: "iztuzu-hospital-1.jpeg", title: "Tortuga con la concha rota"},
			{src: "iztuzu-hospital-2.jpeg", title: "Tortuga en el hospital"},
		],
	},
	{
		type: "p",
		content: [`La vida de la tortuga joven está plagada de peligros, solo 1 huevo de  cada 1000, 
		consigue llegar a la edad adulta, donde después de 25 años de viajes y aventuras volverá a la 
		playa donde nació para poner los huevos.  Es muy importante proteger los nidos,
		 para que el numero máximo de bebés consigan llegar a las aguas.  
		 Los trabajadores de DEKAMER cada noche patrullan sin descanso la playa. 
		 Cuando encuentran una tortuga haciendo el nido, inmediatamente lo protegen con unas jaulas 
		 para que los zorritos y gaviotas no puedan comerse los huevos y lo marcan de forma inconfundible 
		 para que los humanos no estropeen los nidos.`]
	},
	{
		type: "gallery",
		gallery: [
			{src: "iztuzu-cage-1.jpeg", title: "Señales de nidos de tortuga", cols: 3, rows: 2},
			{src: "iztuzu-cage-2.jpeg", title: "Nido de tortuga marina"},
			{src: "iztuzu-cage-3.jpeg", title: "Jaula de protección de nidos de tortuga"},
			{src: "iztuzu-cage-4.jpeg", title: "Gaviota"},
		],
	},
	{
		type: "title",
		content: "Como la playa fue salvada de la construcción hotelera"
	},
	{
		type: "p",
		content: [`Hoy en día Iztuzu parece que está a salvo de la ola masiva de apartamentos y 
		hoteles que ha asolado las costas turcas, pero en los años 80 estuvo a 
		punto de ser destruido. La presión del turismo estaba poniendo en peligro la playa y 
		las tortugas que en ella anidan, hasta llegar al punto en que una compañía alemana,
		ya había empezado a construir un gran complejo hotelero. 
		Por suerte unos años atrás una chica inglesa, June Haimoff, se había enamorado de la 
		playa y cada año veraneaba en una cabaña de madera en la misma playa. Al ver el 
		peligro que acechaba a las tortugas empezó a movilizar a la gente para que 
		protegieran el entorno y las tortugas. Tras mucho esfuerzo y muchas batallas, 
		con la ayuda de la gente local y algunas ONG, consiguieron que la playa fuera 
		un espacio protegido y no se pudiera construir en él. La tarea de June, 
		no terminó ahí, ya que desde entonces se ha dedicado incansablemente
		 a la protección de las tortugas.`]
	},
	{
		type: "title",
		content: "¿Cómo llegar?"
	},
	{
		type: "p",
		content: [`Para llegar a Iztuzu, lo más fácil es ir en coche o usar el 
		transporte público.
		Si decides ir en coche, puedes aparcar justo al inicio de la playa, donde hay un parking especialmente para los turistas. 
		Para los que vayan en transporte público, desde el centro de Dalyan 
		hay minibuses frecuentes que van a la playa, o también puedes se puede 
		ir en un barco turístico que salen desde el rio de Dalyan, cerca de los espectaculares monumentos de Kaunos. 
		Los barcos empieza a las 9:30 de la mañana y retornan a la ciudad cada hora. El precio es de 25 TL ida y vuelta.`]
	},
	{
		type: "gallery",
		gallery: [
			{src: "dalyan.jpeg", title: "Dalyan"},
			{src: "kaunos.jpeg", title: "Kaunos"},
			{src: "boats.jpeg", title: "Barcos turísticos"},
		],
	},
	{
		type: "title",
		content: "Horarios"
	},
	{
		type: "p",
		content: [`La playa está abierta cada día de 8 de la mañana a 8 de la noche.`]
	},
]