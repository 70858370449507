export const translationEn = {
	routes: {
		home: "/",
		about: "about-us",
		animals: "animals",
		activity: "activity",
		activities: "activities",
	},
	menu: {
	  	"home": "Home",
	  	"about": "About Us",
	  	"animals": "Animals",
	},
	general: {
		"learnMore": "Learn more",
		"readMore": "Read more",
	},
  	home: {
  		carousel: {
  			"seal":{
  				"line1": "Mediterranean",
  				"line2": "Monk Seal",
  				"line3": "Izmir, Turkey"
  			},
  			"caretta":{
  				"line1": "Loggerhead",
  				"line2": "Sea turtles",
  				"line3": "Dalyan, Turkey"
  			},
  			"ibis":{
  				"line1": "Northern",
  				"line2": "Bald Ibis",
  				"line3": "Sanliurfa, Turkey"
  			},
  		},
  		boxSustainable: {
        	title: "Sustainable Tourism",
			subtitle: "Not all tourism is good for earth!",
			description: `Travel is very good and important, but a massive amount of people 
				flocking to a small destination generally leads to damage to the environment.
				We want to help to protect these areas, and we believe that with diversification 
				and accommodations that protect the environment, we can reach the point where tourism
				will be helping the world.`
      	},
  		block3Col:{
		  	col1:{
				title: "Travel to the Nature",
				description: `We promote a different kind of tourism 
		  			- to help nature and to prevent the destruction to animals by
		  			promoting accommodations that are helping to protect the environment.`,
			},
			col2: {
				title: "Protect the animals",
				description: `A lot of species are disappearing just next to our homes. 
		   		We will try to give voice to them, as well as to the organizations 
		   		that are protecting them.`
			},
			col3: {
				title: "Discover the wildlife",
				description: `We are surrounded by a lot of unnoticed neighbours, with incredible lifestyles.
		   			We will try to discover a little of their lives 
		               while traveling to their homes.`
			},
		},
		block2Col:  {
			col1:{
				title: "Sustainable Tourism",
				subtitle: "Not all tourism is good for earth!",
				description: `Travel is very good and important, but a massive amount of people 
					flocking to a small destination generally leads to damage to the environment.
					We want to help to protect these areas, and we believe that with diversification 
					 and accommodations that protect the environment, we can reach the point where tourism
					  will be helping the world.`
			},
			col2: {
				title: "Protect the animals",
				subtitle: "A lot of animals are disappearing next to us!",
				description: `Every day, more and more spices are disappearing. 
						The lands are being taken over, by cities, 
						factories and farms. We will try to give voice to them. 
						Next to you maybe you have a colony of flamingos, seals, or turtles; 
						however, with increased human exploitation, 
						their habitat is being reduced day by day.`,
			}
		},
  		activites: {
			title: "Activities",
		},

  		about: {
  			"title": "Hello! I'm Héctor from Barcelona!",
  			"description": "Since 2012 I have been leading a nomad lifestyle travelling around the world. Let's try to build a better tourist network, where the nature can benefit from the tourism and also the tourists get the opportunity to help the world",
  		}
  	},
  	about: {
      	meTitle: "About Me",
      	medescription: [
	        `Hello! I'm Héctor, a website programmer from Barcelona. 
	        Since 2012 I have been leading a nomad lifestyle travelling around the world.`,
	        `While travelling, the environment changes every few days,
	        but I try to manage a routine that allows me to work under 
	        any circumstances. Cafeterias, gas stations, restaurants, hotels 
	        become an improvised office for me.`,
	        `My work requires me to be connected to the computer always and therefore
	        I try to bring the computer to the interesting places I visit.`
	        ],
      	whyTitle: "Why?",
      	whyDescription: [
			`Since I was a child i had a big passion for nature. 
			I was born in a big city and at that time nature was unreachable 
			for me but I liked to spend thousands of hours reading animal guides 
			and nature magazines, or watching wildlife documentaries. 
			`,
			`During adolescence this passion was quite forgotten; 
			however I believe the time has come to tread on this path again. 
			But this time, the exploration of wildlife passion will not be through books; 
			This time I will try to discover it with my own eyes and share it with all of you.`
		],
      	whatTitle: "What?",
      	whatDescription: [
			`Travelling all these years I have seen how fragile the animal world is
			and how it is getting wiped out at an incredible pace.`,
			`These sights disturb me and ignite the childhood passion for wildlife. 
			I would like to give something back to the countries that are hosting me by 
			giving voice to the wildlife. They cannot speak but I will try my best
			to bring their issues in the forefront so that people become aware and 
			are able to help, whichever way possible for the survival of these animal species.
			`,
			`Through this website we will try to promote the organizations that are helping 
			the nature, from the local businesses that are fighting to protect the wildlife, 
			to the organizations that are caring for endangered animals. 
			Let's try to build a better tourist network, 
			where the nature can benefit from the tourism and also the tourists
			get the opportunity to help the world`
      	]
  	},
  	activity:{
  		contact : "Contact",
  		readMore: "Read More",
  		typeLabel: {
  			scenary: "Beautiful Scenary",
			walk: "Walking Route",		
			museum: "Museum / Information Center",
			bicycle: "Bicycle Ride",
			tour: "Tour",
			volunteer: "Volunteering",
  		}
  	}

}