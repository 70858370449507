import React, {Component} from 'react';


import Page1Col from '../base/Page1Col';
import AnimalList from './animalList/AnimalList';


class AnimalListContainer extends Component {
    
	render(){

		let main = <AnimalList />
		return <Page1Col  main={main}  />
	}
}

export default AnimalListContainer